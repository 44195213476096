/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

export default function WineSoilType({ wine, ...rest }) {
  if (!wine?.soilType) return null;
  return (
    <Section title={<Trans>Soil Type</Trans>} {...rest}>
      <FormattedText text={wine.soilType} variant="large" />
    </Section>
  );
}

export const fragment = graphql(`
  fragment bb_WineSoilType on Bottlebooks_Wine {
    soilType
  }
`);

WineSoilType.fragment = gql`
  fragment WineSoilType on Wine {
    soilType
  }
`;
