/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import {
  AwardIcon,
  FormattedText,
  P,
  Text,
} from "@bottlebooks/gatsby-design-system";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { graphql } from "~/gql";
import Section from "../Section/Section";

export default function ProductTastingNotes({ product, ...rest }) {
  const { i18n } = useLingui();
  if (!hasContent(product?.tastingNotes)) return null;
  return (
    <Section title={i18n._(t`Tasting Notes`)} {...rest}>
      {product.tastingNotes.map((note) => (
        <TastingNote
          key={`${note.publication} - ${note.noteDate} – ${note.rating}`}
          tastingNote={note}
        />
      ))}
    </Section>
  );
}

function hasContent(tastingNotes) {
  if (!tastingNotes?.length) return false;
  return tastingNotes.some((tastingNote) =>
    TastingNote.hasContent(tastingNote)
  );
}

function TastingNote({ tastingNote, ...rest }) {
  return (
    <article {...rest}>
      <P>
        <AwardIcon sx={{ marginRight: 2 }} />
        {tastingNote.rating && (
          <Text as="span" variant="semiTitle" sx={{ marginRight: 2 }}>
            {tastingNote.rating}{" "}
          </Text>
        )}
        {tastingNote.publication}
        {tastingNote.noteDate && (
          <Text as="span" variant="small" sx={{ marginRight: 2 }}>
            {" "}
            {tastingNote.noteDate}
          </Text>
        )}
      </P>
      <FormattedText variant="large" text={tastingNote.note} />
      {tastingNote.author && <P>{tastingNote.author}</P>}
    </article>
  );
}
TastingNote.hasContent = function tastingNoteHasContent(tastingNote) {
  if (!tastingNote) return false;
  if (tastingNote.noteDate) return true;
  if (tastingNote.note) return true;
  if (tastingNote.author) return true;
  if (tastingNote.publication) return true;
  if (tastingNote.rating) return true;
};

export const fragment = graphql(`
  fragment bb_ProductTastingNotes on Bottlebooks_Product {
    tastingNotes {
      noteDate
      note
      author
      publication
      rating
    }
  }
`);

ProductTastingNotes.fragment = gql`
  fragment ProductTastingNotes on Product {
    tastingNotes {
      noteDate
      note
      author
      publication
      rating
    }
  }
`;
