/** @jsxImportSource theme-ui */
import { GoogleApiWrapper, Map as BaseMap, Marker } from "google-maps-react";
import styles from "./mapStyles";
import env from "~/env";

const WrappedMap = GoogleApiWrapper({
  apiKey: env.VITE_GOOGLE_MAPS_API_KEY || "",
  language: "en",
})(BaseMap);
// @ts-ignore
WrappedMap.defaultProps = { zoom: 14, style: {}, styles };

export function Map({ initialCenter, className = "", ...rest }) {
  // The map won't initialize correctly if the initial center is not set.
  // So don't render anything in this case.
  if (!initialCenter) return null;
  return (
    <div
      sx={{
        height: "100%",
        minHeight: 200,
        "> div": { height: "100%", position: "relative" },
      }}
      className={className}
    >
      <WrappedMap
        // @ts-ignore
        streetViewControl={false}
        scaleControl={false}
        fullscreenControl={false}
        panControl={false}
        initialCenter={initialCenter}
        {...rest}
      />
    </div>
  );
}

export { Marker };
