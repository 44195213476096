/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { graphql } from "~/gql";
import { getFixedImage } from "../../helpers/getImageProps";
import Image from "../Image/Image";

/** @param {import('cloudinary-core').Transformation.Options} preset */
function preset(preset) {
  return preset;
}
const presets = {
  default: preset({
    width: 120,
    height: 120,
    crop: "pad",
    background: "#fff0",
    quality: "auto:best",
  }),
  large: preset({
    width: 184,
    height: 184,
    crop: "pad",
    background: "#fff0",
    quality: "auto:best",
  }),
  small: preset({
    width: 84,
    height: 84,
    crop: "pad",
    background: "#fff0",
    quality: "auto:best",
  }),
  smaller: preset({
    width: 70,
    height: 70,
    crop: "pad",
    background: "#fff0",
    quality: "auto:best",
  }),
};

export default function ProfileLogo({ brand, onDark, ...rest }) {
  if (!brand) return null;
  if (!brand.logo?.publicId) {
    return <BrandLogoLegacy brand={brand} {...rest} />;
  }
  const { size = "small" } = rest;
  if (presets[size]) {
    return (
      <Image
        publicId={brand.logo.publicId}
        layout="fixed"
        {...rest}
        options={{ ...(presets[size] || presets.default), ...rest.options }}
        sx={{
          borderRadius: "default",
          position: "relative",
          ...dimensions[size],
          boxSizing: "content-box",
          color: "lighterText",
          boxShadow: onDark ? undefined : `border`,
          ...paddings[size],
        }}
      />
    );
  }
  return <Image publicId={brand.logo?.publicId} layout="fixed" {...rest} />;
}

const dimensions = {
  smaller: { width: 32, height: 32 },
  small: { width: 84, height: 84 },
  default: { width: [60, 120], height: [60, 120] },
  large: { width: [142, 184], height: [142, 184] },
};
const paddings = {
  smaller: { padding: 1 },
  small: { padding: [1, 2] },
  default: { boxShadow: undefined },
  large: { padding: [1, 2] },
};

function BrandLogoLegacy({ brand, size = "small", onDark = false, ...rest }) {
  const { logo } = brand || {};
  const hasLogo = Boolean(logo?.fixed);
  return (
    <div
      sx={{
        borderRadius: "default",
        position: "relative",
        ...dimensions[size],
        boxSizing: "content-box",
        color: "lighterText",
        boxShadow: onDark ? undefined : `border`,
        ...paddings[size],
      }}
      {...rest}
    >
      {hasLogo && (
        <div sx={{ width: null, height: null }}>
          <img
            src={logo.fixed?.src}
            alt={brand.name}
            sx={{
              position: "relative",
              borderRadius: "default",
              ...dimensions[size],
              objectFit: "contain",
            }}
            {...rest}
          />
        </div>
      )}
    </div>
  );
}

export const fragment = graphql(`
  # The fragments for the Bottlebooks API using RegisteredBrand
  #############################################
  fragment bb_RegisteredBrand_BrandLogo on Bottlebooks_RegisteredBrand {
    profile {
      name
      logo {
        publicId
      }
    }
  }

  # The fragments for the Bottlebooks API
  #############################################
  fragment bb_BrandLogo on Bottlebooks_AbstractBrand {
    name
    logo {
      publicId
    }
  }

  # Still need to be refactored
  #############################################

  # The fragments for the legacy pubCache API
  #############################################
  fragment BrandLogoSmall on AbstractBrand {
    name
    logo {
      fixed(
        width: 84
        height: 84
        crop: PAD
        background: "#FFF0"
        quality: BEST
      ) {
        src
        srcSet
        height
        width
        base64
      }
    }
  }
  fragment BrandLogoDefault on AbstractBrand {
    name
    logo {
      fixed(
        width: 120
        height: 120
        crop: PAD
        background: "#FFF0"
        quality: BEST
      ) {
        src
        srcSet
        height
        width
        base64
      }
    }
  }

  # Optimized for large lists (only sends the src to save bandwidth)
  fragment BrandLogoSmall_list on AbstractBrand {
    name
    logo {
      publicId
      fixed(
        width: 84
        height: 84
        crop: PAD
        background: "#FFF0"
        quality: BEST
      ) {
        src
        height
        width
      }
    }
  }

  fragment BrandLogoLarge on AbstractBrand {
    name
    logo {
      publicId
      fixed(
        width: 184
        height: 184
        crop: PAD
        background: "#FFF0"
        quality: BEST
      ) {
        src
        srcSet
        height
        width
        base64
      }
    }
  }
  fragment BrandLogoLarge_list on AbstractBrand {
    name
    logo {
      publicId
      fixed(
        width: 368
        height: 368
        crop: PAD
        background: "#FFF0"
        quality: BEST
      ) {
        src
        height
        width
      }
    }
  }
`);

// Client-only pages need a separate fragment. Unfortunately both must be defined separately.
const logoSmall = gql`
  fragment Brand_logo_small on CloudinaryAsset {
    publicId
    fixed(
      width: 84
      height: 84
      crop: PAD
      background: "#FFF0"
      quality: BEST
    ) {
      src
      srcSet
      height
      width
      base64
    }
  }
`;

const logoLarge = gql`
  fragment Brand_logo_large on CloudinaryAsset {
    publicId
    fixed(
      width: 184
      height: 184
      crop: PAD
      background: "#FFF0"
      quality: BEST
    ) {
      src
      srcSet
      height
      width
      base64
    }
  }
`;

export const BrandLogoFragments = {
  small: gql`
    ${logoSmall}
    fragment BrandLogoSmall on AbstractBrand {
      name
      logo {
        ...Brand_logo_small
      }
    }
  `,

  large: gql`
    ${logoLarge}
    fragment BrandLogoLarge on AbstractBrand {
      name
      logo {
        ...Brand_logo_large
      }
    }
  `,
};
