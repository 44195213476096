/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Text } from "@bottlebooks/gatsby-design-system";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import { Fragment } from "react";
import useLink from "../../useLink";
import Skeleton from "../Skeleton";
import type { TextProps } from "./UserProfileCard";

export default function UserProfileCardCompany({
  profile,
  linkToExhibitor,
  ...rest
}: TextProps & {
  profile: UserProfileCardCompanyData;
  linkToExhibitor?: boolean;
}) {
  if (!profile) return null;
  const { jobTitle, companyName } = profile;
  return (
    <Text variant="smallest" sx={{ color: "lighterText" }} {...rest}>
      {jobTitle && companyName ? (
        <Trans>
          {jobTitle} at{" "}
          <CompanyName profile={profile} linkToExhibitor={linkToExhibitor} />
        </Trans>
      ) : (
        <Fragment>
          {jobTitle}
          <CompanyName profile={profile} linkToExhibitor={linkToExhibitor} />
        </Fragment>
      )}
    </Text>
  );
}

function CompanyName({
  profile,
  linkToExhibitor,
}: {
  profile: UserProfileCardCompanyData;
  linkToExhibitor?: boolean;
}) {
  const link = useLink();
  if (!profile.companyName) return null;
  if (!linkToExhibitor) return <Fragment>{profile.companyName}</Fragment>;
  if (!profile.registration?.companyId)
    return <Fragment>{profile.companyName}</Fragment>;
  return (
    <Link
      variant="text"
      to={link.exhibitor({ exhibitorId: profile.registration.companyId })}
    >
      {profile.companyName}
    </Link>
  );
}

// export const fragment = graphql(`
//   fragment UserProfileCardCompany on Bottlebooks_SiteUserProfile {
//     jobTitle
//     companyName
//     registration {
//       companyId
//     }
//   }
// `);

UserProfileCardCompany.fragment = gql`
  fragment UserProfileCardCompany on SiteUserProfile {
    jobTitle
    companyName
    registration {
      companyId
    }
  }
`;

export type UserProfileCardCompanyData = {
  companyName: string | null | undefined;
  jobTitle: string | null | undefined;
  registration: {
    companyId: string | null;
  } | null;
};

export function UserProfileCardCompanySkeleton(rest: TextProps) {
  return (
    <Text variant="smallest" sx={{ paddingBottom: 1 }} {...rest}>
      <Skeleton width="6em" height="1em" />
    </Text>
  );
}
