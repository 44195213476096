/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Button, DownloadIcon } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";

export default function RegistrationInfoSheetButton({ exhibitor, ...rest }) {
  if (!exhibitor?.registrationInfoSheet?.url) return null;
  return (
    <Button
      href={exhibitor?.registrationInfoSheet?.url}
      target="_blank"
      rel="noopener noreferrer"
      variant="outline"
      sx={{ textAlign: "center" }}
      {...rest}
    >
      <DownloadIcon
        size="small"
        sx={{ marginRight: 2, color: "currentColor" }}
      />
      <Trans>Download info sheet</Trans>
    </Button>
  );
}

export const fragment = graphql(`
  fragment bb_RegistrationInfoSheetButton on Bottlebooks_SingleRegistration {
    registrationInfoSheet {
      url
    }
  }
`);

RegistrationInfoSheetButton.fragment = gql`
  fragment RegistrationInfoSheetButton on SingleRegistration {
    registrationInfoSheet {
      url
    }
  }
`;
