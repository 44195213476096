/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Col, Container, Row } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import useLink from "../../useLink";
import BrandLogo, { BrandLogoFragments } from "../Brand/BrandLogo";
import ProducerHeader, {
  ProducerHeaderFragment,
} from "../Producer/ProducerHeader";
import ChangeProducerLink from "./ChangeProducerLink";

export default function ProducerHeaderRow({
  exhibitor,
  producer,
  previous,
  next,
}) {
  const link = useLink();
  return (
    <Container>
      <Row sx={{ justifyContent: "center" }}>
        <Col sx={{ marginTop: [-75, -50] }}>
          <BrandLogo
            size="large"
            brand={producer}
            sx={{ backgroundColor: "background" }}
          />
        </Col>
        <Col
          sx={{
            paddingY: 4,
            flex: "1",
            minWidth: ["100%", "unset"],
            order: [1, "unset"],
          }}
        >
          <ProducerHeader
            as="h1"
            exhibitor={exhibitor}
            producer={producer}
            gap={3}
          />
        </Col>
        <Col sx={{ order: -1, minWidth: `${100 / 12}%`, textAlign: "right" }}>
          <ChangeProducerLink
            producer={previous}
            to={link.producer(previous)}
            direction="previous"
            sx={{ marginLeft: -4, marginRight: -3, marginTop: 3 }}
          />
        </Col>
        <Col sx={{ minWidth: `${100 / 12}%` }}>
          <ChangeProducerLink
            producer={next}
            to={link.producer(next)}
            sx={{ marginLeft: -3, marginRight: -4, marginTop: 3 }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export const fragment = graphql(`
  fragment bb_Registration_ProducerHeaderRow on Bottlebooks_Registration {
    ...bb_Registration_ProducerHeader
  }
  fragment bb_RegisteredBrand_ProducerHeaderRow on Bottlebooks_RegisteredBrand {
    ...bb_RegisteredBrand_ProducerHeader
  }
  fragment ProducerHeaderRow on Producer {
    ...BrandLogoLarge
    ...ProducerHeader
    ...useLink_Producer
  }
`);

export const ProducerHeaderRowFragment = gql`
  ${BrandLogoFragments.large}
  ${ProducerHeaderFragment}
  fragment bb_Registration_ProducerHeaderRow on Bottlebooks_Registration {
    ...bb_Registration_ProducerHeader
  }
  fragment ProducerHeaderRow on Producer {
    ...BrandLogoLarge
    ...ProducerHeader
  }
`;
