/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { FormattedText, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

/**
 * Describes the wine's maturation.
 */
export default function WineMaturation({ wine, ...rest }) {
  if (!wine?.maturation && !wine.oakInfluence && !wine.monthsInBottle)
    return null;
  return (
    <Section title={<Trans>Maturation</Trans>} {...rest}>
      <FormattedText variant="large" text={wine.maturation} />
      <MaturationValue title={<Trans>Oak influence</Trans>}>
        {wine.oakInfluence}
      </MaturationValue>
      <MaturationValue title={<Trans>Months in bottle</Trans>}>
        {wine.monthsInBottle}
      </MaturationValue>
    </Section>
  );
}

function MaturationValue({ title, value = undefined, children }) {
  // Only if the raw value is null or empty we have supplied it.
  if (value === null || value === "") return null;
  if (!children) return null;
  return (
    <Text variant="large">
      {title}: {children}
    </Text>
  );
}

export const fragment = graphql(`
  fragment bb_WineMaturation on Bottlebooks_Wine {
    maturation
    monthsInBottle
    oakInfluence
  }
`);

WineMaturation.fragment = gql`
  fragment WineMaturation on Wine {
    maturation
    monthsInBottle
    oakInfluence
  }
`;
