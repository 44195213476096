/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import BookMeetingButton from "./BrandActions/BookMeetingButton";
import BrandOnlineShopButton from "./BrandActions/BrandOnlineShopButton";

export default function BrandActions({ brand, ...rest }) {
  if (!hasContent(brand)) return null;
  return (
    <Box {...rest}>
      <BrandOnlineShopButton brand={brand} sx={{ marginRight: 2 }} />
      <BookMeetingButton brand={brand} />
    </Box>
  );
}

BrandActions.hasContent = hasContent;

function hasContent(brand) {
  if (BookMeetingButton.hasContent(brand)) return true;
  if (BrandOnlineShopButton.hasContent(brand)) return true;
  return false;
}

export const fragment = graphql(`
  fragment bb_BrandActions on Bottlebooks_AbstractBrand {
    ...bb_BookMeetingButton
    ...bb_BrandOnlineShopButton
  }
`);

BrandActions.fragment = gql`
  fragment BrandActions on AbstractBrand {
    ...BookMeetingButton
    ...BrandOnlineShopButton
  }
  ${BookMeetingButton.fragment}
  ${BrandOnlineShopButton.fragment}
`;
