/** @jsxImportSource theme-ui */
import { Trans } from "@lingui/macro";
import Gallery from "../Gallery/Gallery";
import Section from "../Section/Section";

export default function ProductGallery({ product, ...rest }) {
  if (!hasContent(product)) return null;
  return (
    <Section title={<Trans>Gallery</Trans>} {...rest}>
      <Gallery images={product.galleryImages} />
    </Section>
  );
}

function hasContent(product) {
  if (!product) return false;
  if (product.galleryImages?.filter(Boolean).length) return true;
  return false;
}
