/** @jsxImportSource theme-ui */
import { Flex, OrganicIcon, Text } from "@bottlebooks/gatsby-theme-base/src";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
// @ts-ignore
import vegan from "../../assets/vegan.svg";
// @ts-ignore
import vegetarian from "../../assets/vegetarian.svg";
import Section from "../Section/Section";

function hasContent(product) {
  if (!product) return false;
  // We don't display 'no' values at all, so we don't need to check for false vs. null.
  // But let's make this more explicit by checking for `true` specifically.
  if (product.isVegan === true) return true;
  if (product.isVegetarian === true) return true;
  if (product.isCertifiedOrganic === true) return true;
  if (product.isOrganic === true) return true;
  if (product.isGmoFree === true) return true;
  if (product.localCertifications?.length) return true;
  if (product.internationalCertifications?.length) return true;
  if (product.otherSustainabilityCertifications) return true;
  return false;
}

export default function BrandCertifications({ product: brand, ...rest }) {
  const { i18n } = useLingui();
  if (!hasContent(brand)) return null;
  return (
    <Section title={<Trans>Certifications</Trans>} {...rest}>
      <Flex align="center" gap={3}>
        {brand.isVegetarian && (
          <img
            src={vegetarian}
            alt={i18n._(t`Vegetarian`)}
            title={i18n._(t`Vegetarian`)}
            width="34"
            height="40"
          />
        )}
        {brand.isVegan && (
          <img
            src={vegan}
            alt={i18n._(t`Vegan`)}
            title={i18n._(t`Vegan`)}
            width="34"
            height="40"
          />
        )}
        {(brand.isCertifiedOrganic || brand.isOrganic) && (
          <OrganicIcon
            size="medium"
            alt={i18n._(t`Organic`)}
            title={i18n._(t`Organic`)}
            sx={{
              color: "lighterText",
              padding: 1,
              verticalAlign: "top",
              border: 1,
              borderRadius: "round",
              borderColor: "lighterText",
              width: 30,
              height: 30,
              marginY: "2px",
            }}
          />
        )}

        {brand.isGmoFree && (
          <Text>
            <Trans>GMO free</Trans>
          </Text>
        )}
      </Flex>
    </Section>
  );
}
