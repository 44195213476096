/** @jsxImportSource theme-ui */
import {
  Box,
  Flex,
  Label,
  SemiTitle,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import {
  ToggleFavorite,
  useFavorite,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import BrandLogo from "../Brand/BrandLogo";
import BrandRegion from "../Brand/BrandRegion";
import SeekingDistribution from "../Brand/BrandSeekingDistribution";
import ExhibitorStand from "../Exhibitor/ExhibitorStand";
import ListRow from "../List/ListRow";
import ListRowBody from "../List/ListRowBody";

/**
 *
 * @param {object} props
 * @param {object} props.producer
 * @param {"default" | "info"} [props.variant="default"] 'info' doesn't show favorites toggle
 * @param {string} [props.to]
 * @param {React.MouseEventHandler} [props.onClick]
 * @param {React.ReactNode} [props.children]
 * @param {boolean} [props.alwaysShowCharacteristics] If set, reserves some space for the characteristics of the producer.
 * @returns
 */
export default function ProducerCard({
  producer: baseProducer,
  children,
  variant = "default",
  alwaysShowCharacteristics,
  ...rest
}) {
  // This is necessary in order to support multiple events in a single site.
  // ProducerCard is built to use RegisteredBrand. However, this is not in the Gatsby source yet.
  // Because it is not in Gatsby source, we only have access to the data from the first event.
  // This data is available in allProducer in Gatsby source, though. This is why we build an ugly
  // composite object here.
  const producer = {
    ...baseProducer,
    profile: { ...baseProducer, ...baseProducer.profile },
  };
  // TODO use registrationId: exhibitor.registrationId,
  const favorite = useFavorite(
    producer && { type: "registration", id: producer.exhibitorId }
  );
  return (
    <Box
      sx={{
        position: "relative",
        display: "grid",
        minHeight: 200,
        boxSizing: "border-box",
        backgroundColor: "background",
        boxShadow: "medium",
        borderRadius: "default",
        // Only add hover to links. The `&` is replaced by the class name automatically.
        "a[href]&:hover": {
          boxShadow: "medium",
          " > .bottlebooks-bgimg": {
            transform: "scale(1.01)",
            "::after": {
              backgroundColor: "overlaySecondary",
            },
          },
        },
      }}
    >
      <ListRow
        {...rest}
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex
          justify="center"
          sx={{
            paddingTop: 3,
            paddingBottom: 1,
            width: "100%",
            position: "relative",
          }}
        >
          <Flex justify="center" sx={{ width: "100%" }}>
            <BrandLogo brand={producer.profile} onDark={true} size="large" />
          </Flex>

          <SeekingDistribution
            brand={producer}
            variant="small"
            sx={{
              position: "absolute",
              bottom: 0,
              display: "block",
              marginX: "auto",
            }}
          />
        </Flex>
        <ListRowBody
          sx={{
            marginBottom: 3,
            width: "100%",
            flex: "0 0 auto",
            paddingTop: 1,
            paddingBottom: 0,
          }}
        >
          <ExhibitorStand exhibitor={producer.registration || producer} />
          <SemiTitle sx={{ paddingBottom: 1, paddingTop: 0 }}>
            {producer.name}
          </SemiTitle>
          <BrandRegion brand={producer.profile} />
          {(producer.characteristics?.length > 0 ||
            alwaysShowCharacteristics) && (
            <Box sx={{ minHeight: "18px" }}>
              <Text
                variant="smallest"
                sx={{ color: "lighterText", marginTop: 1 }}
              >
                {producer.characteristics
                  ?.map((characteristc) =>
                    characteristc
                      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                        return index === 0
                          ? word.toUpperCase()
                          : word.toLowerCase();
                      })
                      .replace(/\s+/g, "")
                  )
                  .sort((a, b) => (a > b ? 1 : -1))
                  .join(", ")}
              </Text>
            </Box>
          )}
        </ListRowBody>
      </ListRow>
      <Flex direction="column" sx={{ width: "100%" }}>
        {children}
      </Flex>
      {variant === "default" && (
        <ExhibitedBy producer={producer}>
          <ToggleFavorite
            variant="small"
            isToggled={favorite.isSet}
            onClick={() => favorite.toggle()}
          />
        </ExhibitedBy>
      )}
    </Box>
  );
}

function ExhibitedBy({ producer, children }) {
  const isExhibitorAndProducerSame =
    producer.producerId === producer.exhibitorId;
  const hasMultipleRegistrations = producer.registrationCount > 1;
  if (hasMultipleRegistrations)
    return (
      <HeaderBox
        sx={{
          backgroundColor: "light",
          borderBottom: 1,
          borderColor: "border",
        }}
      >
        <Box sx={{ paddingLeft: 2 }}>
          <Label variant="small">
            <Trans>Presented by</Trans>
          </Label>
          <Text variant="smallest">
            <Trans>Multiple exhibitors</Trans>
          </Text>
        </Box>
        {/* We don't have a way to bookmark multiple exhibitors, so don't show the bookmark toggle */}
        {/* {children} */}
      </HeaderBox>
    );

  if (isExhibitorAndProducerSame)
    return (
      <HeaderBox>
        {/* Empty item to keep toggle right-aligned */}
        <Box />
        {children}
      </HeaderBox>
    );

  return (
    <HeaderBox
      sx={{ backgroundColor: "light", borderBottom: 1, borderColor: "border" }}
    >
      <Box sx={{ paddingLeft: 2 }}>
        <Label variant="small">
          <Trans>Presented by</Trans>
        </Label>
        <Text variant="smallest">{producer.exhibitor.name}</Text>
      </Box>
      {children}
    </HeaderBox>
  );
}

function HeaderBox({ children, ...rest }) {
  return (
    <Flex
      justify="space-between"
      align="center"
      sx={{
        width: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        minHeight: 47,
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export const fragment = graphql(/* GraphQL */ `
  fragment bb_ProducerCard_RegisteredBrand on Bottlebooks_RegisteredBrand {
    brandId
    name
    exhibitorId: companyId
    registrationId
    registration {
      ...bb_ExhibitorCard_Registration
    }
    ...bb_RegisteredBrand_SeekingDistribution
    ...bb_RegisteredBrand_BrandLogo
    ...bb_RegisteredBrand_BrandRegion
  }
`);
