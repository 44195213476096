/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

export default function WineViticulture({ wine, ...rest }) {
  if (!wine?.viticulture) return null;
  return (
    <Section title={<Trans>Viticulture</Trans>} {...rest}>
      <FormattedText text={wine.viticulture} variant="large" />
    </Section>
  );
}

export const fragment = graphql(`
  fragment bb_WineViticulture on Bottlebooks_Wine {
    viticulture
  }
`);

WineViticulture.fragment = gql`
  fragment WineViticulture on Wine {
    viticulture
  }
`;
