/** @jsxImportSource theme-ui */
import { Text, View } from "@bottlebooks/gatsby-design-system";

export default function Scale({ text, value, max = 4, className }) {
  if (value == null) return null;
  const values = new Array(max).fill(0).map((_, index) => index + 1);
  return (
    <View className={className} sx={{ marginBottom: 3 }}>
      <Text>{text}</Text>
      <View sx={{ display: "flex" }}>
        {values.map((scale) => (
          <View
            key={scale}
            sx={{
              width: `${100 / max}%`,
              height: 5,
              backgroundColor: scale <= value ? "primary" : "borderSecondary",
              marginTop: 2,
              marginRight: "2px",
              marginBottom: "9px",
              boxSizing: "border-box",
            }}
          />
        ))}
      </View>
    </View>
  );
}
