/** @jsxImportSource theme-ui */
import { Title } from "@bottlebooks/gatsby-theme-base/src";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import ProductHeaderPrice from "./ProductHeaderPrice.next";
import BaseProductName from "./ProductName.next";
import BaseProducerName from "./ProductProducerName.next";
import BaseRegion from "./ProductRegion";
import BaseStand from "./ProductStand.next";
import BaseVarieties from "./ProductVarieties";

export const fragment = graphql(`
  # TODO: how to migrated from Classic Product to RegisteredProduct?
  # fragment hasDifferentExhibitor on Product {
  #   producer {
  #     name
  #   }
  #   exhibitor {
  #     name
  #   }
  # }

  fragment ProductHeaderDetails_RegisteredProduct on RegisteredProduct {
    ...HasDifferentExhibitor
    registration {
      ...ProductStand
      profile {
        name
      }
    }
    ...ProductPrice
    pricing {
      priceType
    }
    product {
      ...ProductHeaderProductName
      ...ProductHeaderProductRegion
      ...ProductHeaderProducerName
      ...ProductHeaderProductVarieties
      # ...hasDifferentExhibitor
      producer {
        name
      }
    }
  }
`);

export default function ProductHeader({
  product: data,
  gap = 3,
  titleVariant = "small",
  standPath,
  showMorePricingInfo = false,
  showStand = true,
  showProducer = true,
}: {
  product: FragmentType<typeof fragment>;
  gap: number;
  titleVariant: import("@bottlebooks/gatsby-design-system/src/components/Title/titleVariants").TitleVariant;
  standPath: string;
  showMorePricingInfo: boolean;
  showStand: boolean;
  showProducer: boolean;
}) {
  const registeredProduct = useFragment(fragment, data);
  const product = registeredProduct?.product;
  const hasOtherExhibitor = hasDifferentExhibitor({ data: registeredProduct });
  return (
    <React.Fragment>
      {showStand && (
        <ProductStand
          stand={registeredProduct.registration}
          exhibitor={
            hasOtherExhibitor && registeredProduct.registration.profile
          }
          to={hasOtherExhibitor && standPath}
        />
      )}
      {showProducer && (
        <ProductProducer
          data={product?.producer}
          to={!hasOtherExhibitor && standPath}
        />
      )}
      <ProductName
        product={product}
        variant={titleVariant}
        sx={{ paddingBottom: gap }}
      />
      <ProductHeaderDetails
        product={registeredProduct}
        showMorePricingInfo={showMorePricingInfo}
      />
    </React.Fragment>
  );
}

export function ProductStand({ stand, exhibitor, to, ...rest }) {
  return (
    <BaseStand
      stand={stand}
      exhibitor={exhibitor}
      to={to}
      sx={{ paddingBottom: 3, color: "lighterText" }}
      {...rest}
    />
  );
}

const productProducerfragment = graphql(`
  fragment ProductHeaderProducerName on Product {
    ...ProductProducerName
  }
`);

export function ProductProducer({
  data,
  to,
  ...rest
}: {
  data: FragmentType<typeof productProducerfragment>;
  to?: string;
  href?: string;
}) {
  const product = useFragment(productProducerfragment, data);
  return <BaseProducerName product={product} to={to} {...rest} />;
}

const productNameFragment = graphql(`
  fragment ProductHeaderProductName on Product {
    ...ProductName
  }
`);
export function ProductName({
  product: data,
  variant,
  ...rest
}: {
  product: FragmentType<typeof productNameFragment>;
  variant?: import("@bottlebooks/gatsby-design-system/src/components/Title/titleVariants").TitleVariant;
  as?: React.ElementType;
  [x: string]: any;
}) {
  const product = useFragment(productNameFragment, data);
  return (
    <Title variant={variant} {...rest}>
      <BaseProductName {...product} />
    </Title>
  );
}

const productHeaderDetailsFragment = graphql(/* GraphQL */ `
  fragment ProductHeaderDetails on RegisteredProduct {
    ...ProductHeaderPrice
    product {
      ...ProductHeaderProductVarieties
      ...ProductHeaderProductRegion
      ...ProductHeaderProductName
      ...ProductProducerName
    }
  }
`);

export function ProductHeaderDetails({
  data,
  showMorePricingInfo,
  ...rest
}: {
  data: FragmentType<typeof productHeaderDetailsFragment>;
  showMorePricingInfo?: boolean;
}) {
  const registeredProduct = useFragment(productHeaderDetailsFragment, data);
  const product = registeredProduct?.product;
  return (
    <React.Fragment>
      <ProductRegion product={product} />
      <ProductVarieties product={product} />
      <ProductHeaderPrice
        pricing={registeredProduct}
        showMorePricingInfo={showMorePricingInfo}
        sx={{ paddingTop: 1, color: "lightText" }}
        {...rest}
      />
    </React.Fragment>
  );
}

const productRegionFragment = graphql(/* GraphQL */ `
  fragment ProductHeaderProductRegion on Product {
    ...ProductRegion
  }
`);
export function ProductRegion({
  product: data,
  ...rest
}: {
  product: FragmentType<typeof productRegionFragment>;
  [x: string]: any;
}) {
  const product = useFragment(productRegionFragment, data);
  return <BaseRegion product={product} sx={{ paddingBottom: 1 }} {...rest} />;
}

const productVarietiesFragment = graphql(/* GraphQL */ `
  fragment ProductHeaderProductVarieties on Product {
    ...ProductVarieties
  }
`);

export function ProductVarieties({
  product: data,
  ...rest
}: {
  product: FragmentType<typeof productVarietiesFragment>;
  [x: string]: any;
}) {
  const product = useFragment(productVarietiesFragment, data);
  return <BaseVarieties product={product} {...rest} />;
}

const hasDifferentExhibitorFragment = graphql(`
  fragment HasDifferentExhibitor on RegisteredProduct {
    registration {
      profile {
        name
      }
    }
    producer {
      name
    }
  }
`);
export function hasDifferentExhibitor({
  data,
}: {
  data: FragmentType<typeof hasDifferentExhibitorFragment>;
}) {
  const registeredProduct = useFragment(hasDifferentExhibitorFragment, data);
  const producerName = registeredProduct.producer?.name?.trim();
  const exhibitorName = registeredProduct.registration.profile?.name?.trim();
  return Boolean(exhibitorName && producerName !== exhibitorName);
}
