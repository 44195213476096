/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Title } from "@bottlebooks/gatsby-design-system";
import { graphql } from "~/gql";

export default function BrandName({ brand, variant, ...rest }) {
  if (!brand) return null;
  return (
    <Title variant={variant} {...rest}>
      {/* {brand.prefix && <span>{brand.prefix} </span>} */}
      {brand.name}
      {/* {brand.suffix && <span> {brand.suffix}</span>} */}
    </Title>
  );
}

export const fragment = graphql(`
  fragment bb_BrandName on Bottlebooks_AbstractBrand {
    name
  }
  fragment BrandName on AbstractBrand {
    name
  }
`);

export const BrandNameFragment = gql`
  fragment BrandName on AbstractBrand {
    name
  }
`;
