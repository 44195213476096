/** @jsxImportSource theme-ui */
import cssOverlay from "../helpers/cssOverlay";

/**
 *
 * @param {object} props
 * @param {object} props.fluid
 * @param {string} props.alt
 * @param {boolean} [props.withOverlay]
 * @param {'eager' | 'lazy'} [props.loading]
 * @returns
 */
export default function CoverImage({
  fluid,
  alt,
  withOverlay = false,
  ...rest
}) {
  if (!fluid) return null;
  return (
    // @ts-ignore Not sure why TS complains about sx.
    <div sx={{ "::after": withOverlay ? cssOverlay() : undefined }}>
      <img
        src={fluid?.src}
        alt={alt}
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        {...rest}
      />
    </div>
  );
}
