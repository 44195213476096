/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { P, SemiTitle, Text } from "@bottlebooks/gatsby-design-system";
import { Col, Row } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { graphql } from "~/gql";
import Section from "../Section/Section";

const analyticFormat = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
};

function hasContent(product) {
  if (!product) return false;
  if (product.alcohol != null) return true;
  if (product.residualSugar != null) return true;
  if (product.totalAcidity != null) return true;
  if (product.designation != null) return true;
  if (product.pH != null) return true;
  return false;
}

export default function ProductAnalytics({ product, ...rest }) {
  const { i18n } = useLingui();
  if (!hasContent(product)) return null;
  const { alcohol, residualSugar, totalAcidity, designation, pH } = product;
  return (
    <Section {...rest}>
      <Row>
        {alcohol != null && (
          <Col sx={{ width: [null, "50%"] }}>
            <SemiTitle>
              <Trans>Alcohol</Trans>
            </SemiTitle>
            <P variant="title">{i18n.number(alcohol, analyticFormat)}%</P>
          </Col>
        )}
        {(residualSugar != null ||
          totalAcidity != null ||
          designation != null) && (
          <Col sx={{ width: [null, "50%"] }}>
            <SemiTitle>
              <Trans>Analytical data</Trans>
            </SemiTitle>
            {designation && <P variant="title">{designation}</P>}
            {residualSugar != null && (
              <Text>
                <Trans>
                  {i18n.number(residualSugar, analyticFormat)} g/l residual
                  sugar
                </Trans>
              </Text>
            )}
            {totalAcidity != null && (
              <Text>
                <Trans>
                  {i18n.number(totalAcidity, analyticFormat)} g/l acidity
                </Trans>
              </Text>
            )}
            {pH != null && (
              <Text>
                <Trans>{i18n.number(pH, analyticFormat)} pH</Trans>
              </Text>
            )}
          </Col>
        )}
      </Row>
    </Section>
  );
}

export const fragment = graphql(`
  fragment bb_ProductAnalytics on Bottlebooks_Product {
    alcohol
    residualSugar
    totalAcidity
    ... on Bottlebooks_Wine {
      designation
      pH
    }
  }
`);

ProductAnalytics.fragment = gql`
  fragment ProductAnalytics on Product {
    alcohol
    residualSugar
    totalAcidity
    ... on Wine {
      designation
      pH
    }
  }
`;
