/** @jsxImportSource theme-ui */
import {
  Badge,
  Button,
  Flex,
  SearchIcon,
} from "@bottlebooks/gatsby-theme-base/src";
// import LanguageSwitcher from './LanguageSwitcher';
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import MultisiteSwitcher from "@bottlebooks/bottlebooks-site-base/src/components/Layout/MultisiteSwitcher.next";
import TopMenuItems from "@bottlebooks/bottlebooks-site-base/src/components/Layout/TopMenuItems";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { Trans } from "@lingui/macro";
import UserProfileCardAvatar from "../UserProfile/UserProfileCard.Avatar";
import { useFavorites } from "@bottlebooks/bottlebooks-site-bookmarks/src";

export default function MenuActions({
  searchIsVisible = false,
  toggleSearchIsVisible = (_state) => {},
  ...rest
}) {
  return (
    <Flex
      gap={2}
      align="center"
      justify="flex-end"
      sx={{ flexGrow: 1 }}
      {...rest}
    >
      <TopMenuItems />
      {/* <LanguageSwitcher /> */}

      {/* @ts-expect-error MultisiteSwitcher doesn't support the sx prop */}
      <MultisiteSwitcher sx={{ display: ["none", null, "flex"] }} />

      <AccountButton />
      {/* <Button
        to={link.bookmarks()}
        variant="text"
        sx={{
          position: 'relative',
          paddingX: 1,
          paddingY: 0,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Text variant="small">
          <Trans>My list</Trans>
        </Text>
        <BookmarkIcon
          variant={bookmarks?.length ? 'inverted' : 'hollow'}
          sx={{
            color: bookmarks?.length ? 'primary' : null,
            height: 25,
            width: 25,
            marginLeft: 1,
          }}
        />

        {Boolean(bookmarks?.length) && (
          <Text
            variant="small"
            sx={{
              position: 'absolute',
              right: -2,
              top: -2,
              color: 'primary',
            }}
          >
            {bookmarks?.length}
          </Text>
        )}
      </Button> */}
      <Button
        variant="text"
        onClick={() => toggleSearchIsVisible(!searchIsVisible)}
        // @ts-expect-error sx isn't defined on Button
        sx={{ padding: 1, display: ["flex", null, "none"] }}
      >
        <SearchIcon size="small" />
      </Button>
    </Flex>
  );
}

function AccountButton() {
  const { userProfile } = useUserProfile();
  const { favorites } = useFavorites();
  const link = useLink();
  if (userProfile) {
    return (
      <Button
        to={link.tastingList()}
        variant="text"
        sx={{ padding: 1, position: "relative" }}
      >
        <UserProfileCardAvatar variant="small" profile={userProfile} />
        {Boolean(favorites?.length) && (
          <Badge
            sx={{
              position: "absolute",
              paddingY: 0.5,
              backgroundColor: "primary",
              color: "onPrimary",
              right: -3,
              top: "2px",
              transform: "translate(-25%, -25%)",
            }}
          >
            {favorites?.length}
          </Badge>
        )}
      </Button>
    );
  }

  return (
    <Button to={link.tastingList()} variant="primary">
      <Trans>Log in</Trans>
    </Button>
  );
}
