/** @jsxImportSource theme-ui */
import { Col } from "@bottlebooks/gatsby-theme-base/src";
import gql from "graphql-tag";
import React from "react";
import useLink from "../../useLink";
import ChangeProductLink from "./ChangeProductLink";

export default function ProductNavigation({ previous, next }) {
  const link = useLink();
  return (
    <React.Fragment>
      <Col sx={{ order: -1, minWidth: `${100 / 12}%`, textAlign: "right" }}>
        <ChangeProductLink
          product={previous}
          to={previous && link.product(previous)}
          direction="previous"
          sx={{ marginLeft: -4, marginRight: -3, marginTop: 3 }}
        />
      </Col>
      <Col sx={{ minWidth: `${100 / 12}%` }}>
        <ChangeProductLink
          product={next}
          to={next && link.product(next)}
          sx={{ marginLeft: -3, marginRight: -4, marginTop: 3 }}
        />
      </Col>
    </React.Fragment>
  );
}

ProductNavigation.fragment = gql`
  fragment ProductNavigation on Product {
    ...useLink_Product
  }
  ${useLink.productFragment}
`;
