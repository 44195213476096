/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Box, Label, Link } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";

export default function ProducerStand({
  exhibitor,
  producer,
  to,
  href,
  onClick,
  ...rest
}) {
  const { stand, registrationCount } = producer;
  if (registrationCount === 1)
    return (
      <Label {...rest}>
        <Link to={to} onClick={onClick} href={href}>
          {[exhibitor?.stand?.name || stand?.name, exhibitor?.profile?.name]
            .filter(Boolean)
            .join(" — ")}
        </Link>
      </Label>
    );
  if (registrationCount > 1)
    return (
      <Box {...rest}>
        <Label>Presented by multiple exhibitors</Label>
        <Label>See products below</Label>
      </Box>
    );
  // This should never happen
  return null;
}

export const fragment = graphql(`
  fragment bb_Registration_ProducerStand on Bottlebooks_SingleRegistration {
    exhibitorId: brandId
    stand {
      name
    }
    profile {
      name
    }
  }

  fragment ProducerStand on Producer {
    stand {
      name: title
    }
  }
`);

export const ProducerStandFragment = gql`
  fragment bb_Registration_ProducerStand on SingleRegistration {
    exhibitorId: brandId
    stand {
      name
    }
    profile {
      name
    }
  }

  fragment ProducerStand on Producer {
    stand {
      name
    }
  }
`;
