/** @jsxImportSource theme-ui */
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";
import Video from "../Video/Video.next";

const fragment = graphql(`
  fragment BrandVideos_Profile on Profile {
    videos {
      type
      videoId
      title
      ...Video
    }
  }
`);

export default function BrandVideos({
  brand: data,
  ...rest
}: {
  brand: FragmentType<typeof fragment>;
}) {
  if (!hasContent(data)) return null;
  const brand = useFragment(fragment, data);
  if (!brand?.videos?.length) return null;
  const needsTitle = !brand.videos[0]?.title;
  return (
    <Section title={needsTitle && <Trans>Videos</Trans>} {...rest}>
      {brand.videos.map((video) => (
        <Video key={video?.videoId} data={video} />
      ))}
    </Section>
  );
}
BrandVideos.hasContent = hasContent;
function hasContent(data: FragmentType<typeof fragment>) {
  const brand = useFragment(fragment, data);
  if (!brand?.videos?.length) return false;
  return brand.videos.some((video) => Video.hasContent(video));
}
