// Create a provider that will be used to pass the event data to the pages.

import React from "react";
import { FragmentType, useFragment as getFragment, graphql } from "~/gql";

const EventDataContext = React.createContext<{
  exhibitors: Array<ReturnType<typeof toExhibitor>>;
  products: Array<ReturnType<typeof toProduct>>;
}>({
  exhibitors: [],
  products: [],
});

const fragment = graphql(/* GraphQL */ `
  fragment useEventData on Collection {
    collectionId
    ...SiteSearch
    registrations {
      nodes {
        ...toExhibitor
      }
    }
    registeredProducts {
      nodes {
        ...toProduct
      }
    }
  }
`);

export default function EventDataProvider({
  children,
  data,
}: {
  children: React.ReactNode;
  data: FragmentType<typeof fragment>;
}) {
  const collection = getFragment(fragment, data);
  const value = collection
    ? {
        exhibitors: collection.registrations.nodes.map(toExhibitor),
        products: collection.registeredProducts.nodes.map(toProduct),
      }
    : {
        exhibitors: [],
        products: [],
      };
  return (
    <EventDataContext.Provider value={value}>
      {children}
    </EventDataContext.Provider>
  );
}

const toExhibitorFragment = graphql(/* GraphQL */ `
  fragment toExhibitor on SingleRegistration {
    registrationId
    exhibitorId: companyId
    profile {
      name
      countryCode: country(format: RAW)
      stateProvince
      region
      city
      ...BrandLogoLarge
    }
  }
`);

function toExhibitor(data: FragmentType<typeof toExhibitorFragment>) {
  const registration = getFragment(toExhibitorFragment, data);
  return {
    brandId: registration.exhibitorId,
    ...registration,
    ...registration.profile,
  };
}

const toProductFragment = graphql(/* GraphQL */ `
  fragment toProduct on RegisteredProduct {
    productId
    exhibitorId: companyId
    producerId
    registration {
      stand {
        name
      }
      profile {
        name
      }
    }
    product {
      productId
      shortName
      countryName: country
      countryCode: country(format: RAW)
      region
      subregion
      denomination
      producer {
        name
        producerId
      }
      ...ProductImageLarge_list
      ...Search_Product
    }
  }
`);

function toProduct(data: FragmentType<typeof toProductFragment>) {
  const product = getFragment(toProductFragment, data);
  return {
    ...product,
    ...product.product,
    exhibitor: {
      brandId: product.exhibitorId,
      name: product.registration.profile?.name,
    },
  };
}

export function useEventData() {
  const data = React.useContext(EventDataContext);
  return data;
}
