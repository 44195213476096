/** @jsxImportSource theme-ui */
import {
  Box,
  Col,
  Container,
  Label,
  Link,
  Row,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import chunk from "lodash/chunk";
import useLink from "../../useLink";
import BrandCarousel from "../BrandCarousel";
import useMenuQuery from "../Layout/useMenuQuery";

export default function FeaturedExhibitorsSection({
  exhibitors,
  locale,
  ...rest
}) {
  const link = useLink();
  const menuItems = useMenuQuery({ locale });
  const exhibitorMenu = menuItems.find(({ type }) => type === "exhibitors");
  const brandChunks = chunk(exhibitors, 2);
  return (
    <Box {...rest}>
      <SectionTitle
        to={link.exhibitors()}
        linkText={
          exhibitorMenu?.name ? (
            <Trans comment="dynamic replacement for 'See all exhibitors'">
              See all {exhibitorMenu.name} →
            </Trans>
          ) : (
            <Trans>See all exhibitors →</Trans>
          )
        }
      >
        <Link to={link.exhibitors()} sx={{ color: "inherit" }}>
          {exhibitorMenu?.name ? (
            <Trans comment="dynamic replacement for 'Our exhibitors'">
              Our {exhibitorMenu.name}
            </Trans>
          ) : (
            <Trans>Our exhibitors</Trans>
          )}
        </Link>
      </SectionTitle>

      <BrandCarousel
        as="section"
        brandChunks={brandChunks}
        showIndicators
        sx={{ backgroundColor: "white" }}
      />
    </Box>
  );
}

function SectionTitle({ children, to, linkText, ...rest }) {
  return (
    <Container sx={{ paddingY: [0, 2], marginBottom: 4 }} {...rest}>
      <Row direction={["column", "row"]} align="baseline">
        <Col flex>
          <Title sx={{ color: "title" }}>{children}</Title>
        </Col>
        <Col>
          <Link to={to} sx={{ display: "block" }}>
            <Label>{linkText}</Label>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export const fragment = graphql(`
  fragment FeaturedExhibitorsSection on Exhibitor {
    ...BrandCarousel
    ...useLink_exhibitor_AbstractBrand
  }
`);
