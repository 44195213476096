/** @jsxImportSource theme-ui */
import {
  ToggleFavorite,
  useFavorite,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { Col, Container, Row } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import BrandLogo from "../Brand/BrandLogo";
import ExhibitorHeader from "../Exhibitor/ExhibitorHeader";
import ChangeExhibitorLink from "./ChangeExhibitorLink";

const fragment = graphql(`
  fragment ExhibitorHeaderRow on Profile {
    name
    __typename
    ... on CompanyProfile {
      companyId
    }
    ...BrandLogoLarge
    ...ExhibitorHeader
    ...useLink_Exhibitor
  }
`);

export default function ExhibitorHeaderRow({
  data,
  previous,
  next,
}: {
  data: FragmentType<typeof fragment> | null | undefined;

  previous?: object;
  next?: object;
}) {
  const link = useLink();
  const exhibitor = useFragment(fragment, data);
  // TODO use registrationId: exhibitor.registrationId,
  const favorite = useFavorite(
    exhibitor?.__typename === "CompanyProfile"
      ? { type: "registration", id: exhibitor.companyId }
      : undefined
  );
  return (
    <Container>
      <Row sx={{ justifyContent: "center" }}>
        <Col sx={{ marginTop: [-75, -50] }}>
          <BrandLogo
            size="large"
            brand={exhibitor}
            sx={{ backgroundColor: "background" }}
          />
        </Col>
        <Col
          sx={{
            paddingY: 4,
            flex: "1",
            minWidth: ["100%", "unset"],
            order: [1, "unset"],
          }}
        >
          <ExhibitorHeader as="h1" exhibitor={exhibitor} gap={3} />
          <ToggleFavorite
            sx={{ marginTop: 3, marginLeft: -2, padding: 0, paddingRight: 3 }}
            isToggled={favorite.isSet}
            onClick={favorite.toggle}
          />
        </Col>
        <Col sx={{ order: -1, minWidth: `${100 / 12}%`, textAlign: "right" }}>
          <ChangeExhibitorLink
            exhibitor={previous}
            to={link.exhibitor(previous)}
            direction="previous"
            sx={{ marginLeft: -4, marginRight: -3, marginTop: 3 }}
          />
        </Col>
        <Col sx={{ minWidth: `${100 / 12}%` }}>
          <ChangeExhibitorLink
            exhibitor={next}
            to={link.exhibitor(next)}
            sx={{ marginLeft: -3, marginRight: -4, marginTop: 3 }}
          />
        </Col>
      </Row>
    </Container>
  );
}
