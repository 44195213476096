/** @jsxImportSource theme-ui */
import shuffle from "lodash/shuffle";
import React from "react";
import { graphql } from "~/gql";
// import SEO from "../seo.jsx";
import LandingPageSections from "./LandingPageSections";
import { Box } from "@bottlebooks/gatsby-design-system";

export default function LandingPage({
  event,
  localeConfig,
  exhibitors,
  producers,
  location,
  segments,
  locale,
  withOverlay,
  displayedFeaturedExhibitors,
}) {
  const shuffledExhibitors = React.useMemo(
    () => shuffle(exhibitors).slice(0, displayedFeaturedExhibitors),
    [displayedFeaturedExhibitors, exhibitors]
  );
  const shuffledProducers = React.useMemo(
    () => shuffle(producers).slice(0, displayedFeaturedExhibitors),
    [displayedFeaturedExhibitors, producers]
  );
  return (
    <Box>
      {/* <SEO page={{ path: location.href }} /> */}
      <LandingPageSections
        event={event}
        localeConfig={localeConfig}
        videos={event.videos}
        files={event.files?.nodes}
        locale={locale}
        segments={segments}
        shuffledExhibitors={shuffledExhibitors}
        shuffledProducers={shuffledProducers}
        withOverlay={withOverlay}
      />
    </Box>
  );
}

export const fragment = graphql(`
  fragment bb_LandingPage on Bottlebooks_Event {
    ...bb_LandingPageSections
  }

  fragment LandingPage_FeaturedExhibitors on Profile {
    ...LandingPageSections_FeaturedExhibitors
  }

  fragment LandingPage_LocaleConfig on LocaleConfig {
    ...LandingPageSections
  }
`);
