/** @jsxImportSource theme-ui */
// eslint-disable-next-line import/no-unresolved
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
import ProductFeatureIcon from "../ProductsList/ProductFeatureIcon";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(/* GraphQL */ `
  fragment ProductCardFeatures on Product {
    featuredContentTypes
  }
`);

export default function ProductCardFeatures({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  if (!product.featuredContentTypes?.length) return null;
  return (
    <Flex sx={{ width: "100%", justifyContent: "flex-end" }} {...rest}>
      {product.featuredContentTypes?.map((type) => (
        // Product features have to be queried separately by the parent.
        <ProductFeatureIcon key={type} variant="onPrimary" type={type} />
      ))}
    </Flex>
  );
}
