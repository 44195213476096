/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { graphql } from "~/gql";
import useLink from "../../useLink";
import Related from "../Related/Related";
import RelatedProduct, {
  RelatedProductFragment,
} from "../RelatedProduct/RelatedProduct";

export default function RelatedProductsSection({
  registeredProducts,
  exhibitor,
  title,
  link,
  ...rest
}) {
  const links = useLink();
  if (!registeredProducts?.length) return null;
  return (
    <Related {...rest}>
      <Related.Header>
        <Related.Title>{title}</Related.Title>
        {link}
      </Related.Header>
      <Related.List>
        {registeredProducts.map((registeredProduct) => (
          <RelatedProduct
            key={registeredProduct.productId}
            to={links.product({
              ...registeredProduct,
              exhibitorId: exhibitor?.exhibitorId,
            })}
            registeredProduct={registeredProduct}
            sx={{
              width: [`${100 / 3}%`, null, `${100 / 4}%`, `${100 / 6}%`],
            }}
          />
        ))}
      </Related.List>
    </Related>
  );
}

export const fragment = graphql(`
  fragment bb_RelatedProductsSection on Bottlebooks_RegisteredProduct {
    productId
    ...bb_RelatedProduct
    # TODO exhibitorId isnt' always resolved.
    # ...useLink_bb_RegisteredProduct
  }
  fragment RelatedProductsSection on Product {
    id
    ...RelatedProduct
    ...useLink_Product
  }
`);

// export const RelatedProductsSectionFragment = gql`
//   fragment RelatedProductsSection on Product {
//     id
//     ...RelatedProduct
//   }

//   ${RelatedProductFragment}
// `;
