/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { graphql } from "~/gql";
import React from "react";
import BrandName, { BrandNameFragment } from "./BrandName";
import BrandRegion, { BrandRegionFragment } from "./BrandRegion";

export default function BrandHeader({
  brand,
  onDark = false,
  titleVariant = "small",
  gap = 2,
  as,
  ...rest
}) {
  return (
    <React.Fragment>
      <BrandName
        as={as}
        brand={brand || {}}
        variant={titleVariant}
        sx={{ paddingBottom: gap }}
        {...rest}
      />
      <BrandRegion brand={brand} sx={{ color: "text" }} />
    </React.Fragment>
  );
}

export const fragment = graphql(`
  fragment bb_BrandHeader on Bottlebooks_AbstractBrand {
    ...bb_BrandName
    ...bb_BrandRegion
  }
  fragment BrandHeader on AbstractBrand {
    ...BrandName
    ...BrandRegion
  }
  fragment bb_BrandHeader_RegisteredBrand on Bottlebooks_RegisteredBrand {
    profile {
      ...BrandName
      ...BrandRegion
    }
  }
`);

export const BrandHeaderFragment = gql`
  ${BrandNameFragment}
  ${BrandRegionFragment}
  fragment BrandHeader on Exhibitor {
    ...BrandName
    ...BrandRegion
  }
`;
