/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

export default function BrandDescription({ brand, ...rest }) {
  if (!brand || !brand.shortDescription) return null;
  return (
    <Section
      title={
        <Trans comment="The header of the Brand description - about the brand">
          About
        </Trans>
      }
      {...rest}
    >
      <FormattedText text={brand.shortDescription} variant="large" />
    </Section>
  );
}

export const fragment = graphql(`
  fragment BrandDescription on Bottlebooks_AbstractBrand {
    shortDescription
  }
  fragment BrandDescription_Profile on Bottlebooks_Profile {
    shortDescription
  }
`);

export const BrandDescriptionFragment = gql`
  fragment BrandDescription on AbstractBrand {
    shortDescription
  }
`;
