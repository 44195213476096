/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { FormattedText, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

export default function WineVinification({ wine, ...rest }) {
  if (!hasContent(wine)) return null;
  return (
    <Section title={<Trans>Vinification</Trans>} {...rest}>
      <FormattedText text={wine.vinification} variant="large" />

      <VinificationValue
        title={<Trans>Whole cluster fermentation</Trans>}
        value={wine.wholeClusterPercent}
      >
        {wine.wholeClusterPercent}%
      </VinificationValue>
      <VinificationValue title={<Trans>Malolactic fermentation</Trans>}>
        {wine.malolacticFermentation}
      </VinificationValue>
      <VinificationValue title={<Trans>Fermentation type</Trans>}>
        {wine.fermentationType}
      </VinificationValue>
      <VinificationValue title={<Trans>Fermentation vessel type</Trans>}>
        {wine.fermentationVesselTypes?.join(", ")}
      </VinificationValue>
    </Section>
  );
}

function hasContent(wine) {
  if (wine?.vinification) return true;
  if (wine?.malolacticFermentation) return true;
  if (wine?.fermentationType) return true;
  if (wine?.fermentationVesselTypes?.length > 0) return true;
  if (wine?.wholeClusterPercent) return true;
  return false;
}

function VinificationValue({ title, value = undefined, children }) {
  // Only if the raw value is null or empty we have supplied it.
  if (value === null || value === "") return null;
  if (!children) return null;
  return (
    <Text variant="large">
      {title}: {children}
    </Text>
  );
}

export const fragment = graphql(`
  fragment bb_WineVinification on Bottlebooks_Wine {
    vinification
    malolacticFermentation
    fermentationType
    fermentationVesselTypes
    wholeClusterPercent
  }
`);

WineVinification.fragment = gql`
  fragment WineVinification on Wine {
    vinification
    malolacticFermentation
    fermentationType
    fermentationVesselTypes
    wholeClusterPercent
  }
`;
