/** @jsxImportSource theme-ui */
import File from "@bottlebooks/gatsby-shared/src/components/File/File";
import FileList from "@bottlebooks/gatsby-shared/src/components/File/FileList";
import { Box, Title } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";

export default function FilesSection({ files }) {
  if (!files?.length) return null;
  return (
    <Box sx={{ paddingY: 4 }}>
      <Title sx={{ color: "title", marginBottom: 3 }}>
        <Trans>Downloads</Trans>
      </Title>

      <FileList width={208}>
        {files.map((item) => (
          <File
            key={item.fileId}
            item={item}
            width={item?.thumbnail?.width}
            height={item?.thumbnail?.height}
            href={item?.url}
            variant="text"
          />
        ))}
      </FileList>
    </Box>
  );
}

export const fragment = graphql(`
  fragment bb_FilesSection on Bottlebooks_File {
    fileId
    name
    url
    ... on Bottlebooks_UploadedFile {
      thumbnail(width: 208, height: 294) {
        # TODO use a fragment
        src
        srcSet
        width
        height
      }
    }
  }
`);
