/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import BrandActions from "../Brand/BrandActions";
import RegistrationInfoSheetButton from "./ExhibitorActions/RegistrationInfoSheetButton";

export default function ExhibitorActions({ exhibitor, ...rest }) {
  return (
    <Flex>
      <RegistrationInfoSheetButton exhibitor={exhibitor} {...rest} />
      <BrandActions brand={exhibitor} />
    </Flex>
  );
}

export const fragment = graphql(`
  fragment bb_ExhibitorActions on Bottlebooks_Exhibitor {
    ...bb_BrandActions
  }
  fragment bb_ExhibitorActions_SingleRegistration on Bottlebooks_SingleRegistration {
    ...bb_RegistrationInfoSheetButton
  }
`);
ExhibitorActions.fragment = gql`
  fragment ExhibitorActions on Exhibitor {
    ...BrandActions
  }
  fragment ExhibitorActions_SingleRegistration on SingleRegistration {
    ...RegistrationInfoSheetButton
  }
  ${BrandActions.fragment}
  ${RegistrationInfoSheetButton.fragment}
`;
