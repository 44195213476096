/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Text } from "@bottlebooks/gatsby-design-system";
import { graphql } from "~/gql";
import React from "react";
import Flag from "../Flag/Flag";
import locationHierarchy from "./locationHierarchy";

/**
 *
 * @param {object} props
 * @param {object} props.brand
 * @param {React.ReactNode} [props.children]
 */
export default function BrandRegion({ brand, children, ...rest }) {
  if (children) {
    return (
      <Text variant="smallest" {...rest}>
        {children}
      </Text>
    );
  }
  return (
    <BrandRegion brand={brand} {...rest}>
      <DefaultContent brand={brand} />
    </BrandRegion>
  );
}

export function DefaultContent({ brand }) {
  if (!brand) return null;
  return (
    <React.Fragment>
      <Flag
        country={brand.countryCode}
        // We don't need to provide an alt text because we add the countryName directly next to the flag.
        alt=""
        sx={{ marginRight: 0.5, verticalAlign: "baseline" }}
      />{" "}
      <span>{locationHierarchy({ brand }).filter(Boolean).join(", ")}</span>
    </React.Fragment>
  );
}

export const fragment = graphql(`
  fragment bb_BrandRegion on Bottlebooks_AbstractBrand {
    countryName: country
    stateProvince
    countryCode: country(format: RAW)
    region
    city
  }
  fragment BrandRegion on AbstractBrand {
    countryName: country(full: true)
    stateProvince
    countryCode: country(full: false)
    region
    city
  }
  fragment bb_RegisteredBrand_BrandRegion on Bottlebooks_RegisteredBrand {
    profile {
      countryName: country
      stateProvince
      countryCode: country(format: RAW)
      region
      city
    }
  }
`);

export const BrandRegionFragment = gql`
  fragment BrandRegion on AbstractBrand {
    countryName: country(format: LOCALIZED)
    countryCode: country(format: RAW)
    region
    city
  }
`;
