/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { P } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

export default function BrandFounded({ brand, ...rest }) {
  if (!brand || !brand.founded) return null;
  return (
    <Section title={<Trans>Founded</Trans>} {...rest}>
      <P variant="title">{brand.founded}</P>
    </Section>
  );
}

export const fragment = graphql(`
  fragment BrandFounded_Profile on Bottlebooks_Profile {
    founded
  }
  fragment BrandFounded on Bottlebooks_AbstractBrand {
    founded
  }
`);

export const BrandFoundedFragment = gql`
  fragment BrandFounded on AbstractBrand {
    founded
  }
`;
