/** @jsxImportSource theme-ui */
import {
  ArrowLeftIcon,
  // ArrowRightIcon,
  Box,
  Flex,
  Link,
} from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import useLink from "../useLink";
import FeaturedBrand from "./Brand/FeaturedBrand";

/**
 * A carousel using only CSS.
 * @see https://css-tricks.com/css-only-carousel/ for the implementation.
 * It displays two exhibitors per slide, as in the design.
 * @typedef {object} Brand
 * @property {string} brandId
 * @typedef {Brand[]} BrandChunk
 *
 * @param {object} props The props.
 * @param {BrandChunk[]} props.brandChunks An array of two brands.
 * @param {boolean} [props.showIndicators] Should indicators below the carousel be showing?
 * @param {string} [props.className] The class name for styling the carousel.
 * @param {string} [props.as]
 */
export default function BrandCarousel({
  brandChunks,
  showIndicators,
  className,
  ...rest
}) {
  const lastIndex = brandChunks.length - 1;
  const hasMultipleSlides = lastIndex > 0;
  return (
    <Box {...rest}>
      <Flex
        // The carousel viewport.
        as="ol"
        className={className}
        sx={{
          display: "flex",
          listStyle: "none",
          margin: 0,
          padding: 0,
          // Add smooth scrolling.
          overflowX: brandChunks.length > 1 ? "scroll" : undefined,
          scrollBehavior: "smooth",
          scrollSnapType: "x mandatory",
          // Hide the scroll bar.
          scrollbarColor: "transparent transparent",
          scrollbarWidth: "none",
        }}
      >
        {brandChunks?.map((brands, index) => (
          <DualSlide
            // A slide containing two exhibitors.
            key={brands?.[0]?.brandId || index}
            brands={brands}
            as="li"
            id={`slide${index + 1}`}
            previousId={
              hasMultipleSlides && `slide${index === 0 ? lastIndex + 1 : index}`
            }
            nextId={
              hasMultipleSlides && `slide${index === lastIndex ? 1 : index + 2}`
            }
            sx={{
              listStyle: "none",
              margin: 0,
              padding: 0,
              // Ensure 100% width per slide.
              flex: hasMultipleSlides ? ["0 0 95%", "0 0 90%"] : "0 0 100%",
              // Snap to the slides when scrolling.
              scrollSnapAlign: "center",
            }}
          />
        ))}
      </Flex>
      {showIndicators && hasMultipleSlides && (
        <Flex justify="center">
          {brandChunks?.map((brands, index) => (
            <Indicator
              key={brands?.[0]?.brandId || index}
              id={`slide${index + 1}`}
              brands={brands}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
}

function Indicator({ id, brands }) {
  return (
    <Link
      href={`#${id}`}
      sx={{
        borderRadius: "round",
        fontSize: 0,
        paddingX: 4,
        paddingY: 1,
        marginY: 3,
        marginX: 1,
        backgroundColor: "primary",
        transition: "backgroundColor",
        ":hover": { backgroundColor: "secondary" },
      }}
    >
      {brands.map(({ name }) => name).join(", ")}
    </Link>
  );
}

/** 
  A single slide displaying two brands.
 */
function DualSlide({ brands, id, nextId, previousId, ...rest }) {
  const link = useLink();
  return (
    <Box
      id={id}
      sx={{
        position: "relative",

        // Display the arrows on hover only.
        ":hover>.bottlebooks-carousel-arrow": {
          transform: "translate(0,-50%)",
          opacity: 1,
        },
      }}
      {...rest}
    >
      {brands?.map((brand) => (
        <FeaturedBrand
          key={brand.brandId}
          brand={brand}
          to={link.exhibitor(brand)}
          sx={{ height: `${100 / brands.length}%` }}
        />
      ))}
      {previousId && (
        <NavigationButton
          // This button is transparent, but executes the actual action.
          direction="previous"
          href={`#${previousId}`}
          sx={{
            // Display the arrow on hover only.
            opacity: 0,
            transform: "translate(-16px, -50%)",
          }}
          className="bottlebooks-carousel-arrow"
        />
      )}
      {nextId && (
        <NavigationButton
          // This button is transparent, but executes the actual action.
          direction="next"
          href={`#${nextId}`}
          sx={{
            // Display the arrow on hover only.
            opacity: 0,
            transform: "translate(16px, -50%)",
          }}
          className="bottlebooks-carousel-arrow"
        />
      )}
    </Box>
  );
}

/**
 * A button to go to the next/previous slide.
 * @param {object} props The props.
 * @param {'previous'|'next'} props.direction The direction.
 * @param {string} props.href The link to the slide.
 * @param {object} props.sx The styles (transformed by jsx into className).
 * @param {string} props.className The class name for styling.
 */
function NavigationButton({ direction = "previous", ...rest }) {
  return (
    <Link
      // @ts-ignore
      sx={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: "raised",
        marginX: 3,
        width: 90,
        height: 90,
        lineHeight: "90px", // Centers the icon vertically.
        textAlign: "center",
        [direction === "next" ? "right" : "left"]: 0,
        borderRadius: "round",
        boxShadow: "floating",
        color: "lightestText",
        backgroundColor: "nuanced",
        transition: (theme) =>
          // @ts-ignore
          `${theme.transition.opacity}, ${theme.transition.transform}, ${theme.transition.color}`,
      }}
      {...rest}
    >
      {direction === "previous" ? (
        <ArrowLeftIcon size="medium" sx={{ color: "currentColor" }} />
      ) : (
        <ArrowLeftIcon
          size="medium"
          sx={{ color: "currentColor", transform: "rotate(180deg)" }}
        />
      )}
    </Link>
  );
}

export const fragment = graphql(`
  fragment bb_BrandCarousel on Bottlebooks_AbstractBrand {
    brandId
    ...bb_FeaturedBrand
  }

  fragment BrandCarousel on AbstractBrand {
    brandId
    ...FeaturedBrand
  }
`);
