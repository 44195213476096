/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { graphql } from "~/gql";
import CoverImage from "../CoverImage";

export default function BrandBanner({ name, mainImage, className }) {
  const image = mainImage;
  return (
    <div
      sx={{
        minHeight: [200, 250],
        position: "relative",
        backgroundColor: "light",
      }}
      className={className}
    >
      {image && (
        <CoverImage
          fluid={image.fluid}
          loading="eager"
          sx={{ position: ["absolute", null, "static"] }}
          alt={name}
        />
      )}
    </div>
  );
}

export const fragment = graphql(`
  fragment bb_BrandBanner_RegistrationProfile on Bottlebooks_RegistrationProfile {
    name
    mainImage {
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
  fragment bb_BrandBanner_BrandProfile on Bottlebooks_BrandProfile {
    name
    mainImage {
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
  fragment bb_BrandBanner on Bottlebooks_AbstractBrand {
    name
    mainImage {
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
  fragment BrandBanner on AbstractBrand {
    name
    mainImage {
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
`);

// Client-only pages need a separate fragment. Unfortunately both must be defined separately.
const mainImage = gql`
  fragment BrandBanner_mainImage on CloudinaryAsset {
    fluid(maxWidth: 1280, maxHeight: 360, crop: FILL, gravity: FACES_AUTO) {
      src
      srcSet
      sizes
      base64
      aspectRatio
    }
  }
`;

export const BrandBannerFragment = gql`
  ${mainImage}
  fragment BrandBanner on AbstractBrand {
    name
    ... on Exhibitor {
      mainImage {
        ...BrandBanner_mainImage
      }
    }
    ... on Producer {
      mainImage {
        ...BrandBanner_mainImage
      }
    }
  }
`;
