/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Box, Grid, Label, P, Text } from "@bottlebooks/gatsby-design-system";
import { attendeeProfileFormSchema } from "@bottlebooks/gatsby-plugin-firebase-auth/src/attendeeProfileSchema";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { Button, Dialog } from "@bottlebooks/gatsby-theme-base/src";
import { keyframes } from "@emotion/react";
import { Trans, t } from "@lingui/macro";
import type { DialogProps } from "@reach/dialog";
import { Form, Formik, useFormikContext } from "formik";
import { graphql } from "~/gql";
import { ChatText, Spinner, User } from "@phosphor-icons/react";
import type { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { CheckboxField, TextField } from "../Form/Field";
import { ImageField } from "../Form/ImageField";
import type { FormActions } from "./RequestChatButton.StateMachine";
import UserProfileCard from "./UserProfileCard";
import type { UserProfileCardCompanyData } from "./UserProfileCard.Company";
import UserProfileCardCompany from "./UserProfileCard.Company";
import Terms from "./UserProfileForm.Terms";

// We need to pass optional() at the end because toFormikValidationSchema() seems to use it. TODO Verify this.
const requestChatSchema = z.object({
  displayName: attendeeProfileFormSchema.shape.displayName,
  jobTitle: attendeeProfileFormSchema.shape.jobTitle.optional(),
  companyName: attendeeProfileFormSchema.shape.companyName.optional(),
  // Telephone is required because we use it for iMessage/WhatsApp.
  telephone: attendeeProfileFormSchema.shape.telephone.pipe(
    z.string().min(1, t`Please enter your telephone number.`)
  ),
  linkedInURL: attendeeProfileFormSchema.shape.linkedInURL.optional(),
  profileImageUrl: attendeeProfileFormSchema.shape.profileImageUrl.optional(),
  profileImage: attendeeProfileFormSchema.shape.profileImage.optional(),
  // We don't need to validate because it's displayed only.
  email: z.string().optional(),
  message: z
    .string({ required_error: t`Please provide a message to your partner.` })
    .trim(),
  allowChat: z.boolean(),
  acceptTerms: z.boolean().refine((value) => value, {
    message: t`You must accept the terms and conditions.`,
  }),
  agreeToShareContactDetails: z.boolean().refine((value) => value, {
    message: t`You must agree to share your contact details.`,
  }),
});

export type RequestChatSchema = z.infer<typeof requestChatSchema>;
type RequestChatFormProps = FormActions & {
  profile: RequestChatButtonFormData;
  onDismiss: DialogProps["onDismiss"];
};
export default function RequestChatButtonForm({
  profile,
  onNext,
  onDismiss,
}: RequestChatFormProps) {
  const { userProfile } = useUserProfile();
  return (
    <Formik<RequestChatSchema>
      // Make sure the form is reinitialized if the login changes.
      // This means that any form state is lost when changing the login!
      // It's probably just slightly inconvenient because it should happen only in rare cases that the uid is changed.
      key={userProfile?.uid}
      initialValues={{
        displayName: userProfile?.displayName || "",
        email: userProfile?.email || "",
        telephone: userProfile?.telephone || "",
        companyName: userProfile?.companyName || "",
        jobTitle: userProfile?.jobTitle || "",
        linkedInURL: userProfile?.linkedInURL || "",
        profileImageUrl: userProfile?.profileImageUrl || "",
        message: "",
        allowChat: false,
        acceptTerms: true,
        agreeToShareContactDetails: false,
      }}
      validationSchema={toFormikValidationSchema(requestChatSchema)}
      validateOnMount={true}
      onSubmit={onNext}
    >
      <Form>
        <Dialog.Header onDismiss={onDismiss}>
          <Trans>Start a chat with {profile.displayName}</Trans>
        </Dialog.Header>
        <Dialog.Content
          sx={{ display: ["block", "grid"], gridTemplateColumns: "320px 1fr" }}
        >
          <Dialog.InfoPanel>
            <P>
              <Trans>
                Here you can send an initial message to {profile.displayName}.
              </Trans>
            </P>
            <P>
              <Trans>
                {profile.displayName} will respond directly to your provided
                email address, or via WhatsApp or iMessage if you prefer.
              </Trans>
            </P>

            <RequestChatRecipient
              label={<Trans>You will chat with</Trans>}
              profile={profile}
              sx={{ marginTop: 4 }}
            />
          </Dialog.InfoPanel>
          <Dialog.MainPanel>
            <TextField name="displayName" label={<Trans>Your name</Trans>} />
            <TextField
              name="email"
              disabled
              label={<Trans>Your email</Trans>}
            />
            <TextField name="jobTitle" label={<Trans>Your job title</Trans>} />
            <TextField name="companyName" label={<Trans>Company</Trans>} />
            <TextField
              name="telephone"
              label={<Trans>Your telephone number</Trans>}
            />
            <TextField name="linkedInURL" label={<Trans>LinkedIn URL</Trans>} />
            <ImageField
              name="profileImage"
              originalURL={userProfile?.profileImageUrl || ""}
              label={<Trans>Profile image</Trans>}
              sx={{ gridColumn: "1" }}
            />
            <TextField
              name="message"
              as="textarea"
              rows={5}
              label={<Trans>Your message</Trans>}
              sx={{ marginBottom: 3 }}
            />

            <CheckboxField
              name="allowChat"
              label={
                <Text variant="small">
                  <Trans>
                    The recipient is allowed to reply by{" "}
                    <span sx={{ color: "#1dd03f" }}>WhatsApp</span> or{" "}
                    <span sx={{ color: "#197cf7" }}>iMessage</span>.
                  </Trans>
                </Text>
              }
            />
            <CheckboxField
              name="agreeToShareContactDetails"
              label={
                <Text variant="small">
                  <Trans>
                    I agree to share my contact details with{" "}
                    {profile.displayName} and the owner of the website for the
                    purpose of facilitating communications.
                  </Trans>
                </Text>
              }
            />
            {!userProfile?.hasAcceptedTerms && <Terms />}
          </Dialog.MainPanel>
        </Dialog.Content>
        <Dialog.Footer onDismiss={onDismiss}>
          <SendButton>
            <Trans>Send message</Trans>
          </SendButton>
        </Dialog.Footer>
      </Form>
    </Formik>
  );
}

RequestChatButtonForm.fragment = gql`
  fragment RequestChatButtonForm on SiteUserProfile {
    uid
    displayName
    jobTitle
    companyName
    telephone
    linkedInURL

    profileImageUrl
    profileImage {
      url
      publicId
      cloudName
    }
    ...UserProfileCardCompany
  }
  ${UserProfileCardCompany.fragment}
`;
// 👆 We need to get the fragment from the child component instead of UserProductCard.Company.fragment,
// probably because of a circular dependency.

// export const fragment = graphql(`
//   fragment RequestChatButtonForm on Bottlebooks_SiteUserProfile {
//     uid
//     displayName
//     jobTitle
//     companyName
//     telephone
//     linkedInURL
//     profileImageUrl
//     profileImage {
//       url
//       publicId
//       cloudName
//     }
//     ...UserProfileCardCompany
//   }
// `);

export type RequestChatButtonFormData = UserProfileCardCompanyData & {
  uid: string;
  displayName: string | null;
  jobTitle: string | null;
  companyName: string | null;
  telephone: string | null;
  linkedInURL: string | null;
  profileImageUrl: string | null | undefined;
  profileImage: {
    url: string | null;
    publicId: string | null;
    cloudName: string | null;
  } | null;
};

const rotateCenter = keyframes`
  0% { transform: rotate(0) }
  to { transform: rotate(360deg) }
`;

function SendButton({ children }: PropsWithChildren<{}>) {
  // I extracted this button to be able to use a hook for checking isSubmitting.
  const { isSubmitting } = useFormikContext();
  if (isSubmitting) {
    return (
      <Button variant="primary" type="submit" disabled>
        <Spinner
          size={24}
          weight="light"
          sx={{ animation: `${rotateCenter} .6s ease-in-out infinite both` }}
        />
        <Trans>Sending…</Trans>
      </Button>
    );
  }
  return (
    <Button variant="primary" type="submit">
      <ChatText size={24} weight="light" />
      {children}
    </Button>
  );
}

type GridProps = Omit<ComponentProps<typeof Grid>, "children" | "sx">;
type RequestChatRecipientProps = GridProps & {
  profile: RequestChatButtonFormData;
  label?: ReactNode;
};
function RequestChatRecipient({
  profile,
  label,
  ...rest
}: RequestChatRecipientProps) {
  return (
    <Grid
      sx={{ gridTemplateColumns: "16px auto", columnGap: 2, rowGap: 0 }}
      {...rest}
    >
      <User size={16} weight="light" sx={{ marginTop: 0.5 }} />
      <Box>
        {label && (
          <Label variant="small" sx={{ color: "inherit", fontWeight: "bold" }}>
            {label}
          </Label>
        )}
        <Grid sx={{ gridTemplateColumns: "30px auto", marginTop: 1 }}>
          <UserProfileCard.Avatar variant="compact" profile={profile} />
          <Box>
            <UserProfileCard.DisplayName
              profile={profile}
              sx={{ fontWeight: "normal" }}
            />
            <UserProfileCard.Company profile={profile} />
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}
