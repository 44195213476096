/** @jsxImportSource theme-ui */
import { useFormikContext } from "formik";
import { graphql } from "~/gql";
import Meeting from "./Meeting";
import type { RequestMeetingButtonTimeFormSchema } from "./RequestMeetingButton.TimeForm";
import type { TimeslotData } from "./Timeslot.Field";
import { gql } from "@apollo/client";
import Timeslot from "./Timeslot";

export type RequestMeetingButtonInfoProps = {
  recipient: RequestMeetingButtonInfoData;
};

export default function RequestMeetingButtonInfo({
  recipient,
}: RequestMeetingButtonInfoProps) {
  const { values } = useFormikContext<RequestMeetingButtonTimeFormSchema>();
  const start = values.start;
  const timeslot = recipient.collection.meetingTimeslots.find(
    (timeslot) => timeslot.id === start
  );
  return (
    <Meeting
      meeting={{
        recipient,
        dateLabel: timeslot?.dateLabel,
        timeslotLabel: timeslot?.timeslotLabel,
      }}
    />
  );
}
RequestMeetingButtonInfo.fragment = gql`
  fragment RequestMeetingButtonInfo on SiteUserProfile {
    displayName
    profileImage {
      url
      publicId
    }
    collection {
      ..._apollo_eventId
      meetingTimeslots(
        experiment: "california-networking"
        experimentToken: "Used in @bottlebooks/gatsby-theme-event, added by bernhard@unserwein.at at 2023-02-25"
      ) {
        location
        isAvailable
        ...Timeslot
      }
    }
  }

  fragment _apollo_eventId on Collection {
    __typename
    ... on Event {
      eventId
    }
    ... on ProductList {
      productListId
    }
  }
  ${Timeslot.fragment}
`;

// export const fragment = graphql(`
//   fragment RequestMeetingButtonInfo on Bottlebooks_SiteUserProfile {
//     displayName
//     profileImage {
//       url
//       publicId
//     }
//     collection {
//       meetingTimeslots(
//         experiment: "california-networking"
//         experimentToken: "Used in @bottlebooks/gatsby-theme-event, added by bernhard@unserwein.at at 2023-02-25"
//       ) {
//         location
//         isAvailable
//         ...Timeslot
//       }
//     }
//   }
// `);

export type RequestMeetingButtonInfoData = {
  displayName: string | null;
  profileImage: {
    url: string;
    publicId: string | null;
  } | null;
  collection: {
    meetingTimeslots: ({
      location: string | null;
      isAvailable: string | null;
    } & TimeslotData)[];
  };
};
