/** @jsxImportSource theme-ui */
import BrandSocialMedia from "@bottlebooks/bottlebooks-site-base/src/components/Brand/BrandSocialMedia";
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
// import { graphql } from "gatsby";

export default function SocialMediaBlock({
  facebookUrl,
  twitterUrl,
  instagramUrl,
  youtubeUrl,
  alignment,
  ...rest
}) {
  return (
    <Flex
      direction="column"
      sx={(theme) => {
        return {
          alignItems:
            alignment === "center"
              ? "center"
              : alignment === "right"
              ? "flex-end"
              : "flex-start",
        };
      }}
      {...rest}
    >
      <BrandSocialMedia
        brand={{ facebookUrl, twitterUrl, instagramUrl, youtubeUrl }}
      />
    </Flex>
  );
}

// export const fragment = graphql`
//   fragment SocialMediaBlockFragment on SanitySocialMediaBlock {
//     facebookUrl
//     twitterUrl
//     instagramUrl
//     youtubeUrl
//     alignment
//     style {
//       ...BlockStyle
//     }
//   }
// `;
