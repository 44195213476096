/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

export default function WineHarvestNotes({ wine, ...rest }) {
  if (!wine?.harvestNotes) return null;
  return (
    <Section title={<Trans>Harvest Notes</Trans>} {...rest}>
      <FormattedText variant="large" text={wine.harvestNotes} />
    </Section>
  );
}

export const fragment = graphql(`
  fragment bb_WineHarvestNotes on Bottlebooks_Wine {
    harvestNotes
  }
`);

WineHarvestNotes.fragment = gql`
  fragment WineHarvestNotes on Wine {
    harvestNotes
  }
`;
