/** @jsxImportSource theme-ui */
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";

export const fragment = graphql(`
  fragment ProductName on Product {
    __typename
    shortName
    ... on Wine {
      vintage(removeNonVintage: true)
    }
  }
`);

export default function ProductName(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  const wine = product.__typename === "Wine" ? product : null;
  if (!product.shortName) return null;
  return (
    <React.Fragment>
      {product.shortName}
      {wine?.vintage && <span sx={{ opacity: 0.7 }}> {wine?.vintage}</span>}
    </React.Fragment>
  );
}
