/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

/**
 * Describes the product's palate.
 */
export default function ProductPalateDescription({ product, ...rest }) {
  if (!product?.palate) return null;
  return (
    <Section title={<Trans>Palate</Trans>} {...rest}>
      <FormattedText text={product.palateDescription} variant="large" />
    </Section>
  );
}

export const fragment = graphql(`
  fragment bb_ProductPalateDescription on Bottlebooks_Product {
    palateDescription
  }
`);

ProductPalateDescription.fragment = gql`
  fragment ProductPalateDescription on Product {
    palateDescription
  }
`;
