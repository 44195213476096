/** @jsxImportSource theme-ui */
import { gql as apolloGql } from "@apollo/client";
import Card from "@bottlebooks/gatsby-design-system/src/components/Card/Card";
import { graphql } from "~/gql";
import React from "react";
import useLink from "../../useLink";
import { ExhibitorListRow } from "../ExhibitorsList";
import { ProducerListRow } from "../ProducersList";
import ProductCard from "../ProductCard/ProductCard.next";
import { useExhibitor } from "../SiteSearch/useEventData";
import FlightListRow from "./FlightListRow";
import ProductsList from "./ProductsList";

/**
 *
 * @param {object} props
 * @param {object} props.exhibitorId
 * @param {Array<object>} props.products
 * @param {'exhibitor' | 'producer' | 'room' | 'flightName' | 'hidden'} [props.groupedBy]
 * @param {string} [props.to]
 * @param {React.MouseEventHandler} [props.onClick]
 * @param {React.ReactNode} [props.children]
 * @returns
 */
export default function GroupedProductsCard({
  exhibitorId,
  groupedBy = "exhibitor",
  products,
  ...rest
}) {
  const link = useLink();
  const exhibitorData = useExhibitor(exhibitorId);
  const producer = products?.[0]?.producer;
  const flightName = products?.[0]?.flightName;
  if (groupedBy === "flightName")
    return (
      <FlightListRow flightName={flightName}>
        <ProductsList sx={{ marginBottom: 4 }}>
          {products.map((product) => (
            <ProductCard
              key={product.productId}
              product={product}
              elevation="flat"
            />
          ))}
        </ProductsList>
      </FlightListRow>
    );
  return (
    <Card
      sx={{ gap: 3, padding: 3, gridTemplateRows: "auto", gridAutoRows: "1fr" }}
      {...rest}
    >
      {groupedBy === "exhibitor" ? (
        <ExhibitorListRow
          elevation="flat"
          exhibitor={exhibitorData}
          to={exhibitorData && link.exhibitor(exhibitorData)}
        />
      ) : groupedBy === "producer" ? (
        <ProducerListRow
          elevation="flat"
          producer={{ exhibitorId, ...producer }}
          exhibitor={exhibitorData}
          to={exhibitorData && link.exhibitor(exhibitorData)}
        />
      ) : null}
      <ProductsList repeat="fit">
        {products.map((product) => (
          <ProductCard
            key={product.productId}
            product={product}
            elevation="flat"
          />
        ))}
      </ProductsList>
    </Card>
  );
}

// HEADSUP: Product features have to be queried separately by the parent.

export const fragment = graphql(/* GraphQL */ `
  fragment bb_GroupedProductsCard on Bottlebooks_Product {
    ...bb_ProductCard
    # This is used to get the logo for the producer in the ProducerListRow
    producer {
      ...bb_BrandLogo
    }
  }
  fragment GroupedProductsCard on Product {
    ...ProductCard
  }

  # A fragment for the first page of products. It has more detailed images.
  fragment GroupedProductsCard_first on Product {
    ...ProductCard_first
  }

  fragment bb_GroupedProductsCard_RegisteredProduct on Bottlebooks_RegisteredProduct {
    ...bb_FlightListRow
  }
`);

// This is currently not used anywhere because we don't preview products page.
// GroupedProductsCard.fragment = apolloGql/* GraphQL */ `
//   fragment GroupedProductsCard on Product {
//     ...ProductCard
//     # This is used to get the logo for the producer in the ProducerListRow
//     producer {
//       ...bb_BrandLogo
//     }
//   }
//   ${ProductCard.fragment}
// `;
