/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import Skeleton from "../Skeleton";
import type { TextProps } from "./UserProfileCard";
import { graphql } from "~/gql";
import { gql } from "@apollo/client";

export default function UserProfileCardDisplayName({
  profile,
  ...rest
}: TextProps & { profile: UserProfileCardDisplayNameData }) {
  if (!profile) return null;
  return (
    <Text variant="small" sx={{ fontWeight: "bold" }} {...rest}>
      {profile.displayName}
    </Text>
  );
}

// export const fragment = graphql(`
//   fragment UserProfileCardDisplayName on Bottlebooks_SiteUserProfile {
//     displayName
//   }
// `);

UserProfileCardDisplayName.fragment = gql`
  fragment UserProfileCardDisplayName on SiteUserProfile {
    displayName
  }
`;

export type UserProfileCardDisplayNameData = {
  displayName: string | null | undefined;
};

export function UserProfileCardDisplayNameSkeleton(rest: TextProps) {
  return (
    <Text sx={{ paddingBottom: 1 }} {...rest}>
      <Skeleton width="10em" height="1em" />
    </Text>
  );
}
