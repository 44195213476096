/** @jsxImportSource theme-ui */
import { Col } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import BrandAnnualProduction from "./BrandAnnualProduction";
import BrandCertifications from "./BrandCertifications";
import BrandDescription from "./BrandDescription";
import BrandFounded from "./BrandFounded";
import BrandGallery from "./BrandGallery";
import BrandRegionSection from "./BrandRegionSection.next";
import BrandVideos from "./BrandVideos.next";
import BrandWinemaker from "./BrandWinemaker.next";
import PresentedBrands from "./PresentedBrands.next";
import BrandViticulturist from "./BrandViticulturist.next";

export const fragment = graphql(`
  fragment BrandDetailsSection on Profile {
    name
    ...BrandDescription_Profile
    # ...BrandWinemaker_Profile
    ...BrandFounded_Profile
    ...BrandAnnualProduction_Profile
    ...BrandTourism_Profile
    ...BrandGallery_Profile
    ...BrandVideos_Profile
    # ...BrandContacts_Profile
  }
  # fragment BrandDetailsSection on AbstractBrand {
  #   ...BrandDescription
  #   ...BrandWinemaker
  #   ...BrandRegionSection
  #   ...BrandFounded
  #   ...BrandTourism
  #   ...BrandGallery
  #   ...BrandVideos
  #   ...BrandContacts
  # }
`);

const presentedBrandsFragment = graphql(`
  fragment BrandDetailsSection_PresentedBrand on RegisteredBrand {
    ...PresentedBrands
  }
`);

export default function BrandDetailsSection({
  data,
  presentedBrandsData,
  ...rest
}: {
  data: FragmentType<typeof fragment> | null | undefined;
  presentedBrandsData: FragmentType<typeof presentedBrandsFragment>[];
} & React.HTMLAttributes<HTMLDivElement>) {
  const brand = useFragment(fragment, data);
  if (!brand) throw new Error("No brand.");
  const presentedBrands = useFragment(
    presentedBrandsFragment,
    presentedBrandsData
  );
  return (
    <Col flex {...rest}>
      <BrandDescription brand={brand} />
      <BrandCertifications product={brand} />
      <PresentedBrands data={presentedBrands} />
      <BrandWinemaker data={brand} />
      <BrandViticulturist data={brand} />
      <BrandRegionSection data={brand} />
      <BrandFounded brand={brand} />
      <BrandAnnualProduction brand={brand} />
      {/* <BrandTourism brand={brand} /> */}
      <BrandGallery brand={brand} />
      <BrandVideos brand={brand} />
      {/* <BrandContacts brand={brand} /> */}
    </Col>
  );
}
