/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Button, ShoppingCartIcon } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";

export default function BrandOnlineShopButton({ brand, ...rest }) {
  if (!hasContent(brand)) return null;
  return (
    <Button href={brand.onlineShop} openInNewTab variant="primary" {...rest}>
      <ShoppingCartIcon
        size="small"
        sx={{ marginRight: 2, color: "currentColor" }}
      />
      <Trans>Go to online shop</Trans>
    </Button>
  );
}
BrandOnlineShopButton.hasContent = hasContent;

function hasContent(brand) {
  if (!brand) return false;
  if (brand.onlineShop) return true;
  return false;
}

export const fragment = graphql(`
  fragment bb_BrandOnlineShopButton on Bottlebooks_AbstractBrand {
    onlineShop
  }
`);

BrandOnlineShopButton.fragment = gql`
  fragment BrandOnlineShopButton on AbstractBrand {
    onlineShop
  }
`;
