/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Badge, Label } from "@bottlebooks/gatsby-design-system";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import React from "react";

export const fragment = graphql(`
  fragment ProductStand on SingleRegistration {
    profile {
      name
    }
    stand {
      name
      standTypeWithNumber
      roomName
    }
  }
`);

export default function ProductStand({
  stand: data,
  exhibitor,
  to,
  href,
  onClick,
  ...rest
}: {
  stand: FragmentType<typeof fragment>;
  exhibitor?: any;
  to?: string;
  href?: string;
  onClick?: () => void;
}) {
  const stand = useFragment(fragment, data);
  if (!hasContent({ stand, exhibitor })) return null;
  return (
    <Label {...rest}>
      <Link to={to} onClick={onClick} href={href}>
        {exhibitor?.stand?.roomName ? (
          <Badge
            sx={{
              backgroundColor: "primary",
              color: "onPrimary",
              marginBottom: 1,
            }}
          >
            {exhibitor?.stand?.roomName}
          </Badge>
        ) : null}
        {[stand?.standTypeWithNumber, exhibitor?.name]
          .filter(Boolean)
          .join(" — ") || <React.Fragment>&nbsp;</React.Fragment>}
      </Link>
    </Label>
  );
}

function hasContent({ stand, exhibitor }) {
  if (exhibitor?.stand?.roomName) return true;
  if (stand?.standTypeWithNumber) return true;
  if (exhibitor?.name) return true;
  return false;
}
