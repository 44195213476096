/** @jsxImportSource theme-ui */
import { gql, useQuery } from "@apollo/client";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { Dialog } from "@bottlebooks/gatsby-theme-base/src";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { DialogProps } from "@reach/dialog";
import { graphql } from "~/gql";
import type { ContentLocale } from "../../useLocale";
import { useCollection } from "../SiteSearch/useEventData";
import type { UserProfileCardAvatarData } from "../UserProfile/UserProfileCard.Avatar";
import RequestMeetingLogin from "./RequestMeetingButton.Login";
import type { RequestMeetingProfileFormData } from "./RequestMeetingButton.ProfileForm";
import RequestMeetingButtonProfileForm from "./RequestMeetingButton.ProfileForm";
import RequestMeetingSubmissionError from "./RequestMeetingButton.SubmissionError";
import SubmissionSuccess from "./RequestMeetingButton.SubmissionSuccess";
import type { RequestMeetingButtonTimeFormData } from "./RequestMeetingButton.TimeForm";
import RequestMeetingButtonTimeForm from "./RequestMeetingButton.TimeForm";
import useMeetingRequestState from "./RequestMeetingButton.useState";
import Timeslot from "./Timeslot";

type RequestMeetingDialogProps = Omit<DialogProps, "children"> & {
  profile: RequestMeetingDialogData;
  collectionId: string;
  locale: ContentLocale;
};

/** The dialog containing the user flow for a meeting request. */
export default function RequestMeetingDialog({
  collectionId,
  locale,
  profile: _baseProfile,
  onDismiss,
  ...rest
}: RequestMeetingDialogProps) {
  const { i18n } = useLingui();
  const { state, onContinue, onBack, meeting } = useMeetingRequestState({
    recipientId: _baseProfile.uid,
  });
  const { userProfile } = useUserProfile();
  const collection = useCollection();
  const { data, loading } = useQuery(query, {
    variables: {
      collectionId: collection.collectionId,
      code: "en",
      attendeeId: _baseProfile.uid,
      organizerId: userProfile?.uid,
    },
    fetchPolicy: "network-only",
  });

  const recipient = {
    ..._baseProfile,
    collection: {
      ..._baseProfile.collection,
      meetingTimeslots: data?.collection.meetingAvailabilities || [],
    },
  };
  const [_requestState, result] = state;
  const requestState = loading ? "LOADING" : _requestState;
  switch (requestState) {
    case "LOADING":
      return (
        <Dialog
          aria-label={i18n._(`Loading your profile…`)}
          onDismiss={onDismiss}
          {...rest}
        >
          <RequestMeetingButtonTimeForm
            recipient={recipient}
            onDismiss={onDismiss}
            initialValues={meeting}
            onContinue={onContinue}
          />
        </Dialog>
      );
    case "LOGIN":
      return (
        <Dialog
          aria-label={i18n._(t`Log in to book a meeting`)}
          onDismiss={onDismiss}
          sx={{ "&&": { maxWidth: 480 } }}
          {...rest}
        >
          <RequestMeetingLogin withProfile={recipient} onDismiss={onDismiss} />
        </Dialog>
      );
    case "SELECT_DATE":
      return (
        <Dialog
          aria-label={i18n._(t`Propose a timeslot for your meeting`)}
          onDismiss={onDismiss}
          {...rest}
        >
          <RequestMeetingButtonTimeForm
            recipient={recipient}
            onDismiss={onDismiss}
            initialValues={meeting}
            onContinue={onContinue}
          />
        </Dialog>
      );
    case "REVIEW_PROFILE":
      return (
        <Dialog
          aria-label={"Review your profile"}
          onDismiss={onDismiss}
          {...rest}
        >
          <RequestMeetingButtonProfileForm
            recipient={recipient}
            onDismiss={onDismiss}
            // We can't lose the profile data, so we merge it with the form data.
            initialValues={{ ...meeting, ...userProfile }}
            onContinue={onContinue}
            onBack={onBack}
          />
        </Dialog>
      );
    case "SUBMITTING":
      return (
        <Dialog
          aria-label={"Submitting meeting request…"}
          onDismiss={onDismiss}
          {...rest}
        >
          <RequestMeetingButtonProfileForm
            recipient={recipient}
            onDismiss={onDismiss}
            initialValues={meeting}
            onContinue={onContinue}
            onBack={onBack}
          />
        </Dialog>
      );
    case "SUCCESS":
      return (
        <Dialog
          aria-label={i18n._(t`Meeting request sent`)}
          onDismiss={onDismiss}
          {...rest}
        >
          <SubmissionSuccess recipient={recipient} onDismiss={onDismiss} />
        </Dialog>
      );
    case "ERROR":
      return (
        <Dialog
          aria-label={i18n._(t`Error booking a meeting`)}
          onDismiss={onDismiss}
          {...rest}
        >
          <RequestMeetingSubmissionError
            message={result}
            profile={recipient}
            onDismiss={onDismiss}
          />
        </Dialog>
      );
    default:
      exhaustiveCheck(requestState);
  }
}

function exhaustiveCheck(_: never): never {
  throw new Error("Unhandled state");
}

RequestMeetingDialog.fragment = gql`
  fragment RequestMeetingDialog on SiteUserProfile {
    id
    displayName
    jobTitle
    companyName
    ...RequestMeetingButtonTimeForm
    ...RequestMeetingButtonProfileForm
  }
  ${RequestMeetingButtonTimeForm.fragment}
  ${RequestMeetingButtonProfileForm.fragment}
`;

// export const fragment = graphql(`
//   fragment RequestMeetingDialog on Bottlebooks_SiteUserProfile {
//     uid
//     displayName
//     jobTitle
//     companyName
//     ...RequestMeetingButtonTimeForm
//     ...RequestMeetingButtonProfileForm
//   }
// `);

export type RequestMeetingDialogData = UserProfileCardAvatarData &
  RequestMeetingButtonTimeFormData &
  RequestMeetingProfileFormData & {
    uid: string;
    displayName: string | null;
    companyName?: string | null | undefined;
    jobTitle?: string | null | undefined;
  };

// Get current availablity of organizer and attendee
const query = gql`
  query TimeFormAttendeeAvailability(
    $collectionId: ID!
    $code: ContentLocale!
    $attendeeId: String!
    $organizerId: String!
  ) {
    collection(collectionId: $collectionId, locale: $code) {
      eventId: collectionId
      name
      meetingAvailabilities(
        experiment: "california-networking"
        experimentToken: "Used in @bottlebooks/gatsby-theme-event, added by bernhard@unserwein.at at 2023-02-25"
        attendeeId: $attendeeId
        organizerId: $organizerId
      ) {
        ...Timeslot
      }
    }
  }
  ${Timeslot.fragment}
`;
