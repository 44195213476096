/** @jsxImportSource theme-ui */
import { Container } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import React from "react";
import EventBanner from "../Event/EventBanner";
import EventHeader from "../Event/EventHeader";
import EventVideos from "./EventVideos.next";
import FeaturedExhibitorsSection from "./FeaturedExhibitorsSection";
import FeaturedProducersSection from "./FeaturedProducersSection";
import FilesSection from "./FilesSection";
import QuickLinks from "./QuickLinks";
import SiteSearchSection from "./SiteSearchSection";

export default function LandingPageSections({
  event,
  localeConfig,
  videos,
  files,
  shuffledExhibitors,
  shuffledProducers,
  withOverlay,
  locale,
  segments,
}) {
  return (
    <React.Fragment>
      <EventBanner event={event} withOverlay={withOverlay} />

      <SiteSearchSection locale={locale} />

      <Container sx={{ paddingY: [4, 5] }}>
        <EventHeader event={event} />
      </Container>

      <Container sx={{ paddingBottom: [4, 5] }}>
        <QuickLinks localeConfig={localeConfig} />
      </Container>

      {EventVideos.hasContent(videos) && (
        <Container sx={{ paddingBottom: [4, 5] }}>
          <EventVideos data={videos} />
        </Container>
      )}

      {Boolean(files.length) && (
        <Container sx={{ paddingBottom: [4, 5] }}>
          <FilesSection files={files} />
        </Container>
      )}

      {Boolean(shuffledExhibitors?.length) && (
        <FeaturedExhibitorsSection
          exhibitors={shuffledExhibitors}
          locale={locale}
          sx={{ paddingBottom: [4, 5] }}
        />
      )}

      {Boolean(shuffledProducers?.length) && (
        <FeaturedProducersSection
          producers={shuffledProducers}
          locale={locale}
          sx={{ paddingBottom: [4, 5] }}
        />
      )}
    </React.Fragment>
  );
}

export const fragment = graphql(`
  fragment bb_LandingPageSections on Bottlebooks_Event {
    ...bb_EventBanner
    ...bb_EventHeader
    videos {
      ...bb_EventVideos
    }
    files {
      nodes {
        ...bb_FilesSection
      }
    }
  }

  fragment LandingPageSections on LocaleConfig {
    ...QuickLinks
  }

  fragment LandingPageSections_FeaturedExhibitors on Exhibitor {
    ...FeaturedExhibitorsSection
  }
`);
