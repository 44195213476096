/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import type { ContentLocale } from "../../useLocale";
import type { RadioGroupFieldProps } from "../Form/Field";
import { RadioGroupField, RadioToggleField } from "../Form/Field";
import { graphql } from "~/gql";
import { gql } from "@apollo/client";

type TimeslotFieldProps = Omit<RadioGroupFieldProps, "children"> & {
  timeslots: TimeslotData[];
  locale: ContentLocale;
};

export default function TimeslotField({
  name,
  timeslots,
  locale,
  ...rest
}: TimeslotFieldProps) {
  const { values, setFieldValue } = useFormikContext<{
    day: string;
    start: string;
  }>();
  const day = values.day;
  const start = values[name];
  const currentSlots = timeslots.filter(
    (slot) => day.length && slot.dateLabel === day
  );
  const time = start?.slice(11, 16);
  const isSameDay = currentSlots.some((slot) => slot.dateLabel === day);
  // This is the start time of the meeting, but on the currently selected day.
  // We use this to update the form value when the user changes the day.
  const newStart = isSameDay
    ? start
    : currentSlots.find(
        (slot) => slot.timeslotLabel === time && slot.isAvailable !== false
      )?.id || "";
  useEffect(() => {
    if (newStart !== start) setFieldValue(name, newStart, false);
  }, [name, start, newStart, isSameDay, setFieldValue]);
  if (!currentSlots?.length) {
    return (
      <Text variant="small" sx={{ color: "lighterText" }}>
        <Trans>Select a date to see the available times.</Trans>
      </Text>
    );
  }
  return (
    <RadioGroupField
      name={name}
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "1fr",
        gap: 2.5,
      }}
      {...rest}
    >
      {currentSlots.map(({ id, timeslotLabel, isAvailable }) => (
        <RadioToggleField
          key={timeslotLabel}
          name={name}
          value={id}
          label={timeslotLabel}
          disabled={isAvailable !== true}
          sx={{
            // Increase specificity to override the disabled styles.
            "&&&:disabled+label":
              isAvailable == null
                ? {
                    backgroundColor: "white",
                    borderColor: "borderNuanced",
                    opacity: 0.5,
                  }
                : {},
          }}
        />
      ))}
    </RadioGroupField>
  );
}

// export const fragment = graphql(`
//   fragment TimeslotField on Bottlebooks_MeetingTimeslot {
//     id: start
//     dateLabel
//     timeslotLabel
//     durationInMinutes
//     isAvailable
//   }
// `);
TimeslotField.fragment = gql`
  fragment TimeslotField on MeetingTimeslot {
    id: start
    dateLabel
    timeslotLabel
    durationInMinutes
    isAvailable
  }
`;

export interface TimeslotData {
  id: string;
  dateLabel: string;
  timeslotLabel: string;
  // Duration is set on server using the meeting id
  // to find the corresponding meeting definition.
  // durationInMinutes: number;
  isAvailable: boolean | null;
}
