/** @jsxImportSource theme-ui */
import Card from "../../Card";
import {
  Grid,
  Flex,
  SemiTitle,
  Stack,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { graphql as gatsbyGraphQL } from "~/gql";
import React, { Fragment } from "react";
import {
  ToggleFavorite,
  useFavorite,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import BrandLogo from "../Brand/BrandLogo";
import BrandRegion from "../Brand/BrandRegion";
import SeekingDistribution from "../Brand/BrandSeekingDistribution";
import ExhibitorStand from "../Exhibitor/ExhibitorStand";
import ListRow from "../List/ListRow";
import ListRowBody from "../List/ListRowBody";
import { useLingui } from "@lingui/react";

/**
 *
 * @param {object} props
 * @param {object} props.exhibitor
 * @param {string} [props.to]
 * @param {string} [props.className]
 * @param {React.CSSProperties} [props.style]
 * @param {Parameters<Card>[0]["elevation"]} [props.elevation]
 * @param {React.MouseEventHandler} [props.onClick]
 * @param {React.ReactNode} [props.children]
 * @returns
 */
export default function ExhibitorCard({
  exhibitor,
  className,
  style,
  elevation,
  children,
  ...rest
}) {
  return (
    <ExhibitorCard.Card
      // The styles are applied to the Card component, not the Link component.
      className={className}
      style={style}
      elevation={elevation}
    >
      <ExhibitorCard.Link exhibitor={exhibitor} {...rest}>
        <ExhibitorCard.Logo exhibitor={exhibitor} />
        <ExhibitorCard.Main exhibitor={exhibitor}>
          <ExhibitorCard.Exhibitor exhibitor={exhibitor} />
          <ExhibitorCard.Characteristics exhibitor={exhibitor} />
          {children}
        </ExhibitorCard.Main>
      </ExhibitorCard.Link>
      <ExhibitorCard.Bookmark exhibitor={exhibitor} />
    </ExhibitorCard.Card>
  );
}
ExhibitorCard.Card = Card;
ExhibitorCard.Link = ExhibitorCardLink;
ExhibitorCard.Bookmark = ExhibitorCardBookmark;
ExhibitorCard.Logo = ExhibitorCardLogo;
ExhibitorCard.Main = ExhibitorCardMain;
ExhibitorCard.Exhibitor = ExhibitorCardExhibitor;
ExhibitorCard.Characteristics = ExhibitorCardCharacteristics;

function ExhibitorCardLink({ exhibitor, children, ...rest }) {
  return (
    <ListRow
      {...rest}
      sx={{
        boxSizing: "border-box",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        borderRadius: "default",
      }}
    >
      {children}
    </ListRow>
  );
}

function ExhibitorCardBookmark({ exhibitor, ...rest }) {
  const favorite = useFavorite(
    exhibitor && { type: "registration", id: exhibitor.exhibitorId }
  );
  return (
    <ToggleFavorite
      variant="small"
      isToggled={favorite.isSet}
      onClick={() => favorite.toggle()}
      sx={{ position: "absolute", top: 0, right: 0 }}
    />
  );
}

/**
 *
 * @param {{exhibitor: any; children?: React.ReactNode; [x: string]: any; }} props
 */
function ExhibitorCardLogo({ exhibitor, children, ...rest }) {
  return (
    <Grid sx={{ padding: 3, paddingBottom: 2, position: "relative" }} {...rest}>
      <BrandLogo
        brand={exhibitor}
        size="large"
        sx={{ boxShadow: "none", justifySelf: "center" }}
      />
      {children}
      <Flex direction="column" justify="center" align="center">
        <SeekingDistribution
          brand={exhibitor}
          variant="small"
          sx={{
            position: "absolute",
            bottom: 0,
            display: "block",
            marginX: "auto",
          }}
        />
      </Flex>
    </Grid>
  );
}

function ExhibitorCardMain({ exhibitor, children, ...rest }) {
  return (
    <ListRowBody
      sx={{ width: "100%", flex: "0 0 auto", paddingTop: 1 }}
      {...rest}
    >
      {children}
    </ListRowBody>
  );
}

function ExhibitorCardExhibitor({ exhibitor, ...rest }) {
  return (
    <Stack spacing={1} {...rest}>
      <ExhibitorStand exhibitor={exhibitor} sx={{ color: "lighterText" }} />
      <SemiTitle sx={{ paddingY: 0 }}>{exhibitor.name}</SemiTitle>
      <BrandRegion brand={exhibitor} sx={{ color: "text" }} />
    </Stack>
  );
}

function ExhibitorCardCharacteristics({ exhibitor, ...rest }) {
  const { i18n } = useLingui();
  return (
    <Text variant="smallest" sx={{ color: "lighterText" }} {...rest}>
      {exhibitor.offeringTypes
        ?.map((characteristic) => i18n._(characteristic))
        .sort((a, b) => (a > b ? 1 : -1))
        .join(", ") || <Fragment>&nbsp;</Fragment>}
    </Text>
  );
}

export const fragment = gatsbyGraphQL(/* GraphQL */ `
  fragment bb_ExhibitorCard on Bottlebooks_Exhibitor {
    name
    ...bb_BrandRegion
    offeringTypes
  }

  fragment bb_ExhibitorCard_Registration on Bottlebooks_Registration {
    registrationId
    ...bb_ExhibitorStand_SingleRegistration
  }

  fragment bb_ExhibitorCard_RegisteredBrand on Bottlebooks_RegisteredBrand {
    ...bb_RegisteredBrand_SeekingDistribution
  }

  fragment ExhibitorCard on Exhibitor {
    ...BrandLogoLarge
    ...ExhibitorStand
    # This is required for multi-event sites
    ...BrandRegion
  }

  # A fragment for the first page of exhibitors. It has more detailed images.
  fragment ExhibitorCard_first on Exhibitor {
    ...BrandLogoLarge
  }
`);
