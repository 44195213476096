/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { SemiTitle, View } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { graphql } from "~/gql";
import Scale from "../Scale/Scale";

export default function ProductAromas({ product }) {
  const { i18n } = useLingui();
  if (!product || !hasContent(product)) return null;
  const { aromaIntensity, aromaFruit, aromaFloral, aromaSpice, aromaWood } =
    product;
  return (
    <View>
      <SemiTitle>
        <Trans>Aromas</Trans>
      </SemiTitle>

      <Scale
        text={
          <Trans>
            Aroma intensity: {i18n._(`aromaIntensity.${aromaIntensity}`)}
          </Trans>
        }
        value={intensityScale[aromaIntensity]}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={<Trans>Fruit: {i18n._(`aromaFruit.${aromaFruit}`)}</Trans>}
        value={fruitScale[aromaFruit]}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={<Trans>Floral: {i18n._(`aromaFloral.${aromaFloral}`)}</Trans>}
        value={floralScale[aromaFloral]}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={<Trans>Spice: {i18n._(`aromaSpice.${aromaSpice}`)}</Trans>}
        value={spicyScale[aromaSpice]}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={<Trans>Wood: {i18n._(`aromaWood.${aromaWood}`)}</Trans>}
        value={woodScale[aromaWood]}
        sx={{ maxWidth: 280 }}
      />
    </View>
  );
}

function hasContent({
  aromaIntensity,
  aromaFruit,
  aromaFloral,
  aromaSpice,
  aromaWood,
}) {
  if (aromaIntensity != null) return true;
  if (aromaFruit != null) return true;
  if (aromaFloral != null) return true;
  if (aromaSpice != null) return true;
  if (aromaWood != null) return true;
  return false;
}
ProductAromas.hasContent = hasContent;

// The scale is used to give each text value a ranking.
const intensityScale = {
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};
const fruitScale = {
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};
const floralScale = {
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};
const spicyScale = {
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};
const woodScale = {
  none: 0,
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};

export const fragment = graphql(`
  fragment ProductAromas on Product {
    aromaIntensity
    aromaFruit
    aromaFloral
    aromaSpice
    aromaWood
  }
`);

export const ProductAromasFragment = gql`
  fragment ProductAromas on Product {
    aromaIntensity
    aromaFruit
    aromaFloral
    aromaSpice
    aromaWood
  }
`;
