/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";
import PackagingVariant from "./PackagingVariant";

function hasContent(product) {
  if (!product?.packagingVariants?.length) return false;
  return product.packagingVariants.some((variant) =>
    PackagingVariant.hasContent(variant)
  );
}

export default function ProductPackagingVariants({ product, ...rest }) {
  if (!hasContent(product)) return null;
  return (
    <Section title={<Trans>Packaging</Trans>} {...rest}>
      <Flex gap={3} wrap="wrap">
        {product.packagingVariants.map((packagingVariant, index) => (
          // We only have the index as an identifier - it's ok here because the order can't be changed.
          <PackagingVariant key={index} packagingVariant={packagingVariant} />
        ))}
      </Flex>
    </Section>
  );
}

export const fragment = graphql(`
  fragment bb_ProductPackagingVariants on Bottlebooks_Product {
    packagingVariants {
      ...bb_PackagingVariant
    }
  }
`);

ProductPackagingVariants.fragment = gql`
  fragment ProductPackagingVariants on Product {
    packagingVariants {
      ...PackagingVariant
    }
  }
  ${PackagingVariant.fragment}
`;
