/** @jsxImportSource theme-ui */
import { Box, Col, Container, Title } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import BrandAddressSection from "../Brand/BrandAddressSection.next";
import BrandBanner from "../Brand/BrandBanner";
import BrandShopsSection from "../Brand/BrandShopsSection";
import MarketingActivitiesSection from "../Brand/MarketingActivitiesSection";
import ContentRow from "../ContentRow";
import ExhibitorActions from "../Exhibitor/ExhibitorActions";
import ProductCard from "../ProductCard/ProductCard.next";
import ProductListListRow from "../ProductListsList/ProductListListRow";
import ProductListsList from "../ProductListsList/ProductListsList";
import ProductsList from "../ProductsList/ProductsList";
import ExhibitorHeaderRow from "./ExhibitorHeaderRow.next";
import ExhibitorSections from "./ExhibitorSections.next";
// import SEO from "../seo.js";

const fragment = graphql(/* GraphQL */ `
  fragment ExhibitorPage on Registration {
    registrationId
    companyId
    profile {
      ...BrandBanner
      ...ExhibitorHeaderRow
      ...BrandAddressSection_Profile
      ...BrandShopsSection
      # Not exposed on GraphQL
      # ...MarketingActivitiesSection
    }

    ...ExhibitorActions_SingleRegistration
    ...ExhibitorStand_SingleRegistration

    __typename
    ... on SingleRegistration {
      ...ExhibitorSections
      registeredProducts {
        nodes {
          ...ProductCard_RegisteredProduct
          productId
          companyId
          ...ProductListRow
          product {
            productId
            slug
            ...ProductCard
            ...useLink_Product
          }
        }
      }
    }
  }
`);

export default function ExhibitorPage({
  eventId,
  data,
  productLists,
  previous,
  next,
}: {
  eventId: string;
  data: FragmentType<typeof fragment>;
  productLists?: { id: string; productListId: string }[] | null | undefined;
  previous: any;
  next: any;
}) {
  const registration = useFragment(fragment, data);
  const singleRegistration =
    registration.__typename === "SingleRegistration" ? registration : null;
  const registeredProducts = singleRegistration?.registeredProducts?.nodes;
  const exhibitor = { ...singleRegistration, ...singleRegistration?.profile };
  const hasProducts = registeredProducts && registeredProducts.length !== 0;
  const hasProductLists = productLists && productLists.length !== 0;
  const hasProductsSection = hasProducts || hasProductLists;

  return (
    <Box>
      {/* <SEO page={page} /> */}
      <BrandBanner {...exhibitor} />
      <ExhibitorHeaderRow
        data={registration.profile}
        previous={previous}
        next={next}
      />

      <Container>
        <ContentRow sx={{ paddingY: 3 }}>
          <Col>
            <ExhibitorActions exhibitor={exhibitor} />
          </Col>
        </ContentRow>
      </Container>

      <MarketingActivitiesSection brand={exhibitor} />

      <ExhibitorSections data={singleRegistration} eventId={eventId} />

      <BrandAddressSection data={registration.profile} />

      <Container>
        <ContentRow>
          <Col>
            <BrandShopsSection brand={exhibitor} />
          </Col>
        </ContentRow>
      </Container>

      {hasProductsSection ? (
        <Container fluid sx={{ maxWidth: "container.fluid" }}>
          <Title sx={{ paddingY: 4 }}>
            <Trans>Products</Trans>
          </Title>
          {hasProductLists && (
            <ProductListsList sx={{ marginX: [-3, -2], marginY: -2 }}>
              {productLists.map((productList) => (
                <ProductListListRow
                  key={productList.id}
                  productList={productList}
                  to={link.productList(productList)}
                  sx={{ marginX: [0, 2], marginY: [0, 2] }}
                />
              ))}
            </ProductListsList>
          )}
          {hasProducts && (
            <ProductsList
              count={registeredProducts.length}
              sx={{ marginX: [-3, -2], marginY: -2 }}
            >
              {registeredProducts.map((registeredProduct) => (
                <ProductCard
                  key={registeredProduct.productId}
                  product={registeredProduct}
                  sx={{ marginBottom: [null, null, 3] }}
                />
              ))}
            </ProductsList>
          )}
        </Container>
      ) : null}
    </Box>
  );
}
