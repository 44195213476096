/** @jsxImportSource theme-ui */
import {
  ToggleFavorite,
  useFavorite,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import AddToListButton from "@bottlebooks/bottlebooks-site-lists/src/components/AddToListButton";
import { Box, Flex } from "@bottlebooks/gatsby-design-system";
import { FragmentType, graphql, useFragment } from "~/gql";
import { TastingNoteButton } from "@bottlebooks/bottlebooks-site-tastingnotes/src";
import useLink from "../../useLink";
import {
  ProductHeaderDetails,
  ProductProducer,
  ProductName,
  hasDifferentExhibitor,
} from "../Product/ProductHeader.next";
import ProductLabel from "../Product/ProductLabel";
import ProductImageCol from "../ProductPage/ProductImageCol";
import ProductNavigation from "../ProductPage/ProductNavigation";
import {
  ProductMainCol,
  ProductPageHeaderRow,
} from "../ProductPage/ProductPageHeader.next";
import useSiteConfig from "../useSiteConfig";
import ProductStand from "../Product/ProductStand.next";

export const fragment = graphql(`
  fragment EventProductPageHeaderRegisteredProduct on RegisteredProduct {
    ...ProductLabel_RegisteredProduct
    ...ProductHeaderDetails
    ...ProductHeaderDetails_RegisteredProduct
    ...HasDifferentExhibitor
    registration {
      ...ProductStand
      profile {
        name
      }
    }
    product {
      productId
      producer {
        name
      }
      ...ProductHeaderProductName
      ...ProductHeaderProducerName
      ...EventProductPageHeader
      ...ProductImageLarge
      bottleImage {
        publicId
        sourceUrl
      }
      labelImage {
        publicId
        sourceUrl
      }
      backLabelImage {
        publicId
        sourceUrl
      }
    }
  }

  fragment EventProductPageHeader on Product {
    productId
    producer {
      ...useLink_Producer
    }
  }

  # fragment EventProductPageHeader on Product {
  #   ...ProductImageLarge
  #   ...ProductStand
  #   ...ProductProducerName
  #   ...ProductName
  #   ...ProductHeaderDetails
  # }
`);

export default function EventProductPageHeader({
  product: data,
  next,
  previous,
  standPath,
}: {
  product: FragmentType<typeof fragment>;
  next: string;
  previous: string;
  standPath: string;
}) {
  const registeredProduct = useFragment(fragment, data);
  const product = registeredProduct.product;
  const link = useLink();
  const favorite = useFavorite(
    product && { type: "product", id: product.productId }
  );
  const { listsEnabled } = useSiteConfig();
  const hasOtherExhibitor = hasDifferentExhibitor({ data: registeredProduct });
  return (
    <ProductPageHeaderRow
      sx={{ justifyContent: "center", paddingTop: 4 }}
      direction={["column-reverse", null, "row"]}
    >
      <ProductImageCol product={product} />
      <ProductMainCol>
        <Flex
          direction="column"
          sx={{
            height: [null, null, 480],
            justifyContent: "center",
            marginBottom: [4, null, 0],
          }}
        >
          <ProductLabel product={product} sx={{ marginBottom: 3.5 }} />
          <ProductStand
            stand={registeredProduct?.registration}
            exhibitor={
              hasOtherExhibitor && registeredProduct.registration.profile
            }
            to={hasOtherExhibitor && standPath}
          />
          <ProductProducer
            data={product}
            to={
              // Handle situation where we have imported products to Unspecified producer
              product.producer.isUnspecifiedProducerReplaced
                ? link.exhibitor(product.producer)
                : link.producer(product.producer)
            }
          />
          <ProductName as="h1" product={product} />
          <Box sx={{ marginLeft: -2, marginTop: 2, marginBottom: 2 }}>
            <TastingNoteButton.Large productId={product?.productId} />
          </Box>
          <ProductHeaderDetails
            data={registeredProduct}
            showMorePricingInfo={true}
          />
          {listsEnabled ? (
            <AddToListButton product={product} />
          ) : (
            <ToggleFavorite
              sx={{ marginTop: 3, marginLeft: -2, padding: 0, paddingRight: 3 }}
              isToggled={favorite.isSet}
              onClick={favorite.toggle}
            />
          )}
        </Flex>
      </ProductMainCol>
      <ProductNavigation previous={previous} next={next} />
    </ProductPageHeaderRow>
  );
}
