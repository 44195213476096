/** @jsxImportSource theme-ui */
import { P } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandRegionSection on Registration {
    profile {
      region
    }
  }
  # fragment BrandRegionSection on Bottlebooks_AbstractBrand {
  #   region
  # }
`);

export default function BrandRegionSection({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment> | null;
}) {
  const registration = useFragment(fragment, data);
  if (!registration?.profile?.region) return null;
  return (
    <Section title={<Trans>Growing area</Trans>} {...rest}>
      <P variant="title">{registration.profile.region}</P>
    </Section>
  );
}
