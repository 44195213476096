/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { graphql } from "~/gql";
import React from "react";
import BrandHeader, { BrandHeaderFragment } from "../Brand/BrandHeader";
import ExhibitorStand, { ExhibitorStandFragment } from "./ExhibitorStand";

export default function ExhibitorHeader({
  exhibitor,
  onDark = false,
  gap = 2,
  as = undefined,
  ...rest
}) {
  return (
    <React.Fragment>
      <ExhibitorStand
        exhibitor={exhibitor || {}}
        sx={{ paddingBottom: gap, color: onDark ? "onDark" : "lighterText" }}
      />
      <BrandHeader as={as} brand={exhibitor || {}} {...rest} />
    </React.Fragment>
  );
}

export const fragment = graphql(`
  fragment ExhibitorHeader on Exhibitor {
    ...ExhibitorStand
    ...BrandHeader
  }
`);

export const ExhibitorHeaderFragment = gql`
  ${ExhibitorStandFragment}
  ${BrandHeaderFragment}
  fragment ExhibitorHeader on Exhibitor {
    ...ExhibitorStand
    ...BrandHeader
  }
`;
