/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Text } from "@bottlebooks/gatsby-design-system";
import { graphql } from "~/gql";
import uniq from "lodash/uniq";
import React from "react";
import Flag from "../Flag/Flag";

export default function ProductRegion({
  product,
  children = <DefaultContent product={product} />,
  ...rest
}) {
  return (
    <Text variant="smallest" sx={{ color: "text" }} {...rest}>
      {children}
    </Text>
  );
}

export function DefaultContent({ product }) {
  if (!product) return null;
  return (
    <React.Fragment>
      <Flag
        alt=""
        country={product.countryCode}
        sx={{ marginRight: 1, verticalAlign: "baseline" }}
      />
      <span>
        {uniq([product.countryName, product.denomination])
          .filter(Boolean)
          .join(", ")}
      </span>
    </React.Fragment>
  );
}

export const fragment = graphql(`
  fragment bb_ProductRegion on Bottlebooks_Product {
    countryName: country(format: LOCALIZED)
    countryCode: country(format: RAW)
    denomination
  }
`);

export const ProductRegionFragment = gql`
  fragment ProductRegion on Product {
    countryName: country(format: LOCALIZED)
    countryCode: country(format: RAW)
    denomination
  }
`;
