/** @jsxImportSource theme-ui */
import { P } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import Section from "../Section/Section";

export default function BrandAnnualProduction({ brand, ...rest }) {
  if (!brand || !brand.annualProductionWine) return null;
  return (
    <Section title={<Trans>Annual production (bottles)</Trans>} {...rest}>
      <P variant="title">{brand.annualProductionWine}</P>
    </Section>
  );
}
