/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

export default function ProductPairingDescription({ product, ...rest }) {
  if (!product?.pairingDescription) return null;
  return (
    <Section title={<Trans>Food Pairing</Trans>} {...rest}>
      <FormattedText variant="large" text={product.pairingDescription} />
    </Section>
  );
}

export const fragment = graphql(`
  fragment bb_ProductPairingDescription on Bottlebooks_Product {
    pairingDescription
  }
`);

ProductPairingDescription.fragment = gql`
  fragment ProductPairingDescription on Product {
    pairingDescription
  }
`;
