/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Box, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";

function hasContent(packagingVariant) {
  if (!packagingVariant) return false;
  if (packagingVariant.GTIN) return true;
  if (packagingVariant.bottlesPerCase) return true;
  return false;
}
export default function PackagingVariant({ packagingVariant, ...rest }) {
  if (!hasContent(packagingVariant)) return null;

  return (
    <Box {...rest}>
      <Value title={<Trans>Bottles per case</Trans>}>
        {packagingVariant.bottlesPerCase}
      </Value>
      <Value title={<Trans>GTIN</Trans>}>{packagingVariant.GTIN}</Value>
    </Box>
  );
}
PackagingVariant.hasContent = hasContent;

function Value({ title, children, ...rest }) {
  if (!children) return null;
  return (
    <Text {...rest}>
      {title}: {children}
    </Text>
  );
}

export const fragment = graphql(`
  fragment bb_PackagingVariant on Bottlebooks_ProductPackagingVariant {
    bottlesPerCase
    GTIN
  }
`);

PackagingVariant.fragment = gql`
  fragment PackagingVariant on ProductPackagingVariant {
    bottlesPerCase
    GTIN
  }
`;
