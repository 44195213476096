/** @jsxImportSource theme-ui */
import { Box, Grid, Text } from "@bottlebooks/gatsby-design-system";
import { useLingui } from "@lingui/react";
import type { FieldAttributes } from "formik";
import { ErrorMessage, useField } from "formik";
import type { ComponentPropsWithRef, ReactNode } from "react";
import { useEffect, useState } from "react";
import type { Theme } from "theme-ui";
import UserProfileCardAvatar from "../UserProfile/UserProfileCard.Avatar";

type ImageFieldProps = Omit<
  FieldAttributes<ComponentPropsWithRef<"input"> & { label?: ReactNode }> & {
    originalURL: string;
  },
  "sx"
>;

export function ImageField({
  name,
  originalURL,
  label,
  className,
  ...rest
}: ImageFieldProps) {
  const [, meta, helpers] = useField({ name, ...rest });
  const hasError = Boolean(meta.error && meta.touched);
  return (
    <Box className={className}>
      <Box as="label" sx={{ display: "block" }}>
        <Text variant="small">{label}</Text>
        <Grid sx={{ gridTemplateColumns: "60px 1fr", gap: 2.5 }}>
          <PreviewImage
            name={name}
            originalURL={originalURL}
            sx={{ width: 60, height: 60 }}
          />
          <input
            name={name}
            type="file"
            accept="image/*"
            sx={(theme: Theme) => ({
              borderWidth: 1,
              borderStyle: "dashed",
              borderColor: hasError ? "brand.warningRed" : "borderNuanced",
              boxShadow: hasError
                ? // @ts-expect-error brand colors aren't defined yet.
                  `0 0 0 1px ${theme.colors?.brand?.warningRed}`
                : undefined,
              borderRadius: "default",
              paddingX: 2,
              paddingY: 2,
              display: "block",
              width: "100%",
              fontFamily: "body",
              fontSize: "default",
            })}
            {...rest}
            onChange={(e) => {
              helpers.setTouched(true);
              if (!e.currentTarget.files?.length) return;
              helpers.setValue(e.currentTarget.files[0]);
            }}
          />
        </Grid>
      </Box>
      <ErrorMessage name={name} component={Error} />
    </Box>
  );
}

function PreviewImage({ name, originalURL, ...rest }) {
  const [field] = useField({ name, ...rest });
  const [fileDataURL, setFileDataURL] = useState<string | undefined>(
    originalURL
  );
  useEffect(() => {
    let fileReader: FileReader;
    let isCancel = false;
    if (field.value && field.value instanceof File) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result === "string" && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(field.value);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [field.value]);

  return (
    <UserProfileCardAvatar
      variant="preview"
      profile={{ displayName: "", profileImageUrl: fileDataURL }}
      {...rest}
    />
  );
}

function Error({ children, ...rest }) {
  const { i18n } = useLingui();
  if (!children) return null;
  return (
    <Text sx={{ color: "brand.warningRed", fontSize: "small" }} {...rest}>
      {children?.message ? i18n._(children) : children}
    </Text>
  );
}
