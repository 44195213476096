/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Col, Row } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import Section from "../Section/Section";
import ProductAromas, { ProductAromasFragment } from "./ProductAromas";
import ProductColor, { ProductColorFragment } from "./ProductColor";
import ProductPalate, { ProductPalateFragment } from "./ProductPalate";

export default function ProductSensory({ product, ...rest }) {
  if (!product) return null;
  const hasColor = ProductColor.hasContent(product);
  const hasAromas = ProductAromas.hasContent(product);
  const hasPalate = ProductPalate.hasContent(product);
  if (!hasColor && !hasAromas && !hasPalate) return null;
  return (
    <Section {...rest}>
      {hasColor && <ProductColor product={product} />}
      <Row>
        {hasAromas && (
          <Col sx={{ width: [null, "50%"] }}>
            {<ProductAromas product={product} />}
          </Col>
        )}
        {hasPalate && (
          <Col sx={{ width: [null, "50%"] }}>
            <ProductPalate product={product} />
          </Col>
        )}
      </Row>
    </Section>
  );
}

export const fragment = graphql(`
  fragment ProductSensory on Product {
    ...ProductColor
    ...ProductAromas
    ...ProductPalate
  }
`);

export const ProductSensoryFragment = gql`
  ${ProductColorFragment}
  ${ProductAromasFragment}
  ${ProductPalateFragment}
  fragment ProductSensory on Product {
    ...ProductColor
    ...ProductAromas
    ...ProductPalate
  }
`;
