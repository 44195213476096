/** @jsxImportSource theme-ui */
import { Box, Container, Title, View } from "@bottlebooks/gatsby-design-system";

export default function Section({ variant = "light", children, ...rest }) {
  return (
    <View
      sx={{
        paddingY: 4,
        backgroundColor:
          variant === "accent"
            ? "accent"
            : variant === "grey"
            ? "light"
            : "transparent",
      }}
      {...rest}
    >
      <Container fluid sx={{ maxWidth: "container.fluid" }}>
        {children}
      </Container>
    </View>
  );
}

Section.Wrapper = function ({ children }) {
  return children;
};

Section.Title = function ({ children, ...rest }) {
  return (
    <Title sx={{ marginBottom: 3 }} {...rest}>
      {children}
    </Title>
  );
};

Section.Body = function ({ children, ...rest }) {
  return <Box {...rest}>{children}</Box>;
};
