/** @jsxImportSource theme-ui */
import { Col, Row, Text, Title } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import React from "react";

export default function EventHeader({ event, ...rest }) {
  if (!event) return null;
  return (
    <Row {...rest}>
      {event.subtitle && (
        <Col sx={{ width: ["100%", "50%"] }}>
          <Title sx={{ color: "title", paddingY: 2 }}>
            {event.subtitle?.split?.("\n").map((line, index) => (
              // Display the subtitle's lines separated by line breaks.
              <React.Fragment key={index}>
                {index > 0 && <br />}
                {line}
              </React.Fragment>
            ))}
          </Title>
        </Col>
      )}
      {event.htmlDescription && (
        <Col sx={{ width: ["100%", "50%"], marginX: "auto" }}>
          <Text
            sx={{ color: "lightText", paddingY: 2 }}
            // @ts-ignore We know what we're doing.
            dangerouslySetInnerHTML={{ __html: event.htmlDescription }}
          />
        </Col>
      )}
    </Row>
  );
}

export const fragment = graphql(`
  fragment bb_EventHeader on Bottlebooks_Event {
    name
    subtitle
    htmlDescription: description(format: HTML)
  }
  fragment EventHeader on Event {
    name
    subtitle
    htmlDescription: description(format: HTML)
  }
`);
