/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { graphql } from "~/gql";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export interface LightboxImage {
  title: string | null;
  description: string | null;
  fullPreview: { src?: string } | null;
  full: { src?: string; sizes?: string } | null;
}

interface Props {
  current: LightboxImage;
  next?: LightboxImage;
  previous?: LightboxImage;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
  className?: string;
}
export default function GalleryLightbox(props: Props) {
  return (
    <InnerLightbox
      sx={{
        ".ril-toolbar-left .ril-toolbar__item__child": {
          variant: "title.small",
        },
        ".ril-caption": { variant: "text.default" },
      }}
      {...props}
    />
  );
}

/**
 * To pass the className into wrapperClassName we need to wrap the component once.
 */
function InnerLightbox({
  current,
  next,
  previous,
  onClose,
  onNext,
  onPrevious,
  className,
  ...rest
}: Props) {
  return (
    <Lightbox
      wrapperClassName={className}
      mainSrc={current?.full?.src || ""}
      mainSrcThumbnail={current?.fullPreview?.src || ""}
      imageTitle={current?.title}
      imageCaption={current?.description}
      nextSrc={next?.full?.src || ""}
      nextSrcThumbnail={next?.fullPreview?.src}
      prevSrc={previous?.full?.src || ""}
      onCloseRequest={onClose}
      onMovePrevRequest={onNext}
      onMoveNextRequest={onPrevious}
      {...rest}
    />
  );
}

// export const fragment = graphql(`
//   fragment GalleryLightbox on Bottlebooks_UploadedFile {
//     title
//     description
//     fullPreview: fluid(maxWidth: 360) {
//       src
//     }
//     full: fluid(maxWidth: 1920, background: "#fff") {
//       src
//       sizes
//     }
//   }
// `);

GalleryLightbox.fragment = gql`
  fragment GalleryLightbox on UploadedFile {
    title
    description
    fullPreview: fluid(maxWidth: 360) {
      src
    }
    full: fluid(maxWidth: 1920, background: "#fff") {
      src
      sizes
    }
  }
`;
