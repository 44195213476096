import ExhibitorPage from "@bottlebooks/bottlebooks-site-base/src/components/ExhibitorPage/ExhibitorPage.next";
import { LoaderFunctionArgs, useLoaderData, useParams } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import { graphql } from "~/gql";
import { getFragment } from "~/gql-local/fragment-masking";
import graphQLClient from "~/graphQLClient";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
  exhibitorId: z.string(),
});

const fragment = graphql(/* GraphQL */ `
  fragment ExhibitorPage_Event on Event {
    eventId
    registrations(filter: { companyId: { eq: $companyId } }) {
      nodes {
        __typename
        registrationId
        ... on SingleRegistration {
          ...ExhibitorPage
        }
      }
    }
  }
`);

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader({ params }: LoaderFunctionArgs) {
  const {
    locale,
    collectionId: collectionIdSegment,
    exhibitorId,
  } = paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const result = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query ExhibitorPage(
        $collectionId: ID!
        $companyId: ID!
        $locale: ContentLocale
      ) {
        collection(collectionId: $collectionId, locale: $locale) {
          ...ExhibitorPage_Event
        }
      }
    `),
    {
      collectionId,
      companyId: exhibitorId,
      locale,
    },
    { "bottlebooks-use-request-cache": "true" }
  );
  return result.collection;
}

export default function ProductPageTemplate() {
  const parsed = paramsSchema.parse(useParams());
  const data = useLoaderData() as LoaderData;
  const event = getFragment(fragment, data);
  if (!event?.registrations.nodes) return <div>No registrations found.</div>;
  const registration = event?.registrations.nodes[0];
  return (
    <ExhibitorPage
      eventId={parsed.collectionId}
      data={registration}
      // TODO Provide previous and next for paging through exhibitors.
      previous={undefined}
      next={undefined}
    />
  );
}
