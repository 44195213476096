/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Flex, Title } from "@bottlebooks/gatsby-design-system";
import { graphql } from "~/gql";
import type { ComponentPropsWithoutRef } from "react";
import type { Theme } from "theme-ui";
import { Avatar, jsx } from "theme-ui";
import Skeleton from "../Skeleton";

type Props = Omit<ComponentPropsWithoutRef<typeof Flex>, "children" | "sx"> & {
  profile: UserProfileCardAvatarData;
  variant?: keyof typeof avatarVariants;
};

export default function UserProfileCardAvatar({
  profile,
  variant = "default",
  ...rest
}: Props) {
  if (!profile) return null;
  const props = avatarVariants[variant];
  return (
    <Flex
      gap={0}
      {...props.root}
      sx={(theme: Theme) => ({
        ...props.root.sx(theme),
        margin: "auto",
        backgroundColor: "nuanced",
        transition: "transform",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      })}
      {...rest}
    >
      <AvatarImage profile={profile} variant={variant} />
    </Flex>
  );
}

UserProfileCardAvatar.fragment = gql`
  fragment UserProfileCardAvatar on SiteUserProfile {
    displayName
    profileImage {
      url
      publicId
      cloudName
    }
  }
`;

// export const fragment = graphql(`
//   fragment UserProfileCardAvatar on Bottlebooks_SiteUserProfile {
//     displayName
//     profileImage {
//       url
//       publicId
//       cloudName
//     }
//   }
// `);

export type UserProfileCardAvatarData = {
  displayName: string | null | undefined;
  profileImageUrl?: string | null;
  profileImage?: {
    url: string | null;
    publicId: string | null;
    cloudName: string | null;
  } | null;
};

function AvatarImage({ profile, variant = "default" }: Props) {
  const props = avatarVariants[variant];
  const url = profile.profileImage?.url || profile.profileImageUrl;
  if (!url) {
    return (
      <AvatarPlaceholder
        profile={profile}
        {...props.avatarPlaceholder}
        sx={{
          ...props.avatarPlaceholder.sx,
          color: "lightestText",
        }}
      />
    );
  }
  if (profile.profileImage?.publicId) {
    const { publicId, cloudName } = profile.profileImage;
    return (
      <Avatar
        src={`https://res.cloudinary.com/${cloudName}/image/upload/w_${props.avatar.width},h_${props.avatar.height},c_fill,g_face,dpr_3/${publicId}`}
        {...props.avatar}
      />
    );
  }
  return <Avatar src={url} {...props.avatar} />;
}

type SkeletonProps = Omit<
  ComponentPropsWithoutRef<typeof Flex>,
  "children" | "sx"
> & {
  variant?: keyof typeof avatarVariants;
};
export function UserProfileCardAvatarSkeleton({
  variant = "default",
  ...rest
}: SkeletonProps) {
  const props = avatarVariants[variant];
  return (
    <Flex
      gap={0}
      {...props.root}
      sx={(theme: Theme) => ({
        ...props.root.sx(theme),
        margin: "auto",
        backgroundColor: "nuanced",
        transition: "transform",
        justifyContent: "center",
        alignItems: "center",
      })}
      {...rest}
    >
      <Skeleton
        {...props.avatar}
        sx={{
          ...props.avatar.sx,
          color: "lightestText",
        }}
      />
    </Flex>
  );
}

type AvatarPlaceholderProps = Omit<
  ComponentPropsWithoutRef<typeof Title>,
  "children"
> & {
  profile: { displayName: string | null | undefined };
};

function AvatarPlaceholder({ profile, ...rest }: AvatarPlaceholderProps) {
  const nameTokens = profile.displayName?.toUpperCase().split(/\s+/) || [];
  const placeholder =
    nameTokens.length === 0
      ? ""
      : nameTokens.length === 1
      ? nameTokens[0][0]
      : `${nameTokens[0][0]}${nameTokens[nameTokens.length - 1][0]}`;
  return (
    <Title
      variant="small"
      // @ts-expect-error Not sure why sx is sometimes displayed as invalid.
      sx={{ fontWeight: "bold" }}
      {...rest}
    >
      {placeholder}
    </Title>
  );
}

const avatarVariants = {
  default: {
    root: {
      sx: (theme: Theme) => ({
        width: 100,
        height: 100,
        borderRadius: "round",
        border: "1px solid white",
        boxShadow: `0 0 0 4px ${theme.colors?.nuanced}`,
      }),
    },
    avatar: { width: 100, height: 100, sx: { borderRadius: "round" } },
    avatarPlaceholder: { sx: {} },
  },
  small: {
    root: {
      sx: (theme: Theme) => ({
        width: 34,
        height: 34,
        borderRadius: "round",
        border: "1px solid white",
        boxShadow: `0 0 0 1px ${theme.colors?.nuanced}`,
      }),
    },
    avatar: { width: 32, height: 32, sx: { borderRadius: "round" } },
    avatarPlaceholder: { sx: { fontSize: "small" } },
  },
  preview: {
    root: {
      sx: (theme: Theme) => ({
        width: 60,
        height: 60,
        borderRadius: "large",
        border: "1px solid white",
        boxShadow: `0 0 0 1px ${theme.colors?.nuanced}`,
      }),
    },
    avatar: { width: 58, height: 58, sx: { borderRadius: "default" } },
    avatarPlaceholder: { sx: { fontSize: "small" } },
  },
  compact: {
    root: {
      sx: (theme: Theme) => ({
        width: 34,
        height: 34,
        borderRadius: "large",
        border: "1px solid white",
        boxShadow: `0 0 0 1px ${theme.colors?.nuanced}`,
      }),
    },
    avatar: { width: 32, height: 32, sx: { borderRadius: "default" } },
    avatarPlaceholder: { sx: { fontSize: "small" } },
  },
} as const;
