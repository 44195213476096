/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Flex, Text } from "@bottlebooks/gatsby-design-system";
import { graphql } from "~/gql";
import varietiesIcon from "../../assets/varieties.svg";

export default function ProductVarieties({
  product,
  children = product &&
    product.grapeVarieties &&
    product.grapeVarieties
      .filter(Boolean)
      .map((v) => v.varietyName)
      .join(", "),
}) {
  if (!children) return null;
  return (
    <Flex>
      <img
        src={varietiesIcon}
        width={16}
        height={16}
        alt=""
        sx={{ marginRight: 1, marginTop: "2px" }}
      />
      <Text variant="smallest" sx={{ color: "text" }}>
        {children}
      </Text>
    </Flex>
  );
}

export const fragment = graphql(`
  fragment bb_ProductVarieties on Bottlebooks_Product {
    ... on Bottlebooks_Wine {
      grapeVarieties {
        varietyName
      }
    }
  }
  fragment ProductVarieties on Product {
    grapeVarieties {
      varietyName
    }
  }
`);

export const ProductVarietiesFragment = gql`
  fragment ProductVarieties on Wine {
    grapeVarieties {
      varietyName
    }
  }
`;
