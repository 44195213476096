/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { SemiTitle, Text, View } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { graphql } from "~/gql";
import Scale from "../Scale/Scale";

export default function ProductColor({ product }) {
  const { i18n } = useLingui();
  if (!product || !hasContent(product)) return null;
  const { appearanceIntensity, apparentColor } = product;
  return (
    <View>
      <SemiTitle>
        <Trans>Color</Trans>
      </SemiTitle>
      {apparentColor && (
        <Text sx={{ marginBottom: 2 }}>
          <Trans>Apparent color: {apparentColor}</Trans>
        </Text>
      )}
      <Scale
        text={
          <Trans>
            Intensity: {i18n._(`appearanceIntensity.${appearanceIntensity}`)}
          </Trans>
        }
        value={intensityScale[appearanceIntensity]}
        max={3}
        sx={{ maxWidth: 210 }}
      />
    </View>
  );
}

function hasContent({ appearanceIntensity, apparentColor }) {
  if (appearanceIntensity) return true;
  if (apparentColor) return true;
  return false;
}
ProductColor.hasContent = hasContent;

const intensityScale = {
  pale: 1,
  medium: 2,
  deep: 3,
};

export const fragment = graphql(`
  fragment ProductColor on Product {
    apparentColor
    appearanceIntensity
  }
`);

export const ProductColorFragment = gql`
  fragment ProductColor on Product {
    apparentColor
    appearanceIntensity
  }
`;
