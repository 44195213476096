/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import { Col, Link } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import ProductImage, { ProductImageFragment } from "../Product/ProductImage";
import ProductName, { ProductNameFragment } from "../Product/ProductName.next";
import { gql } from "@apollo/client";

export default function RelatedProduct({ registeredProduct, to, ...rest }) {
  return (
    <Col {...rest}>
      <Link to={to} fallback="div">
        <ProductImage product={registeredProduct?.product} size="default" />
        <Text
          variant="small"
          sx={{
            color: "lighterText",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingY: 2,
          }}
        >
          <ProductName {...registeredProduct?.product} />
        </Text>
      </Link>{" "}
    </Col>
  );
}

export const fragment = graphql(`
  fragment bb_RelatedProduct on Bottlebooks_RegisteredProduct {
    product {
      ...bb_ProductName
      bottleImage {
        ...bb_Image
      }
    }
  }
  fragment RelatedProduct on Product {
    slug
    ...ProductImage
    ...ProductName
  }
`);

// export const RelatedProductFragment = gql`
//   ${ProductImageFragment}
//   ${ProductNameFragment}
//   fragment RelatedProduct on Product {
//     slug
//     ...ProductImage
//     ...ProductName
//   }
// `;
