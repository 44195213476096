/** @jsxImportSource theme-ui */
import { Col } from "@bottlebooks/gatsby-design-system";
import ProductAnalytics from "../Product/ProductAnalytics";
import ProductAwards from "../Product/ProductAwards";
import ProductBottleVariants from "../Product/ProductBottleVariants";
import ProductCertifications from "../Product/ProductCertifications";
import ProductDescription from "../Product/ProductDescription";
import ProductGallery from "../Product/ProductGallery";
import ProductPackagingVariants from "../Product/ProductPackagingVariants";
import ProductPairingDescription from "../Product/ProductPairingDescription";
import ProductPalateDescription from "../Product/ProductPalateDescription";
import ProductRange from "../Product/ProductRange";
import ProductSensory from "../Product/ProductSensory";
import ProductTastingNotes from "../Product/ProductTastingNotes";
import ProductVideos from "../Product/ProductVideos.next";
import WineHarvestNotes from "../Product/WineHarvestNotes";
import WineMaturation from "../Product/WineMaturation";
import WineSoilType from "../Product/WineSoilType";
import WineVineyard from "../Product/WineVineyard";
import WineVinification from "../Product/WineVinification";
import WineViticulture from "../Product/WineViticulture";
import WineWinemaker from "../Product/WineWinemaker.next";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment ProductDetailsSection on Product {
    ...ProductDescription
    ...ProductAwards
    ...ProductTastingNotes
    ...ProductCertifications
    ...ProductAnalytics
    ...ProductGallery
    ...ProductVideos
    ...ProductRange
    ...WineWinemaker
    ...WineSoilType
    ...WineVineyard
    ...WineHarvestNotes
    ...WineViticulture
    ...WineVinification
    ...WineMaturation
    ...ProductPalateDescription
    ...ProductPairingDescription
    ...ProductBottleVariants
    ...ProductPackagingVariants
    ...ProductSensory
  }
`);

export default function ProductDetailsSection({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  return (
    <Col flex>
      <ProductDescription product={product} />
      <ProductAwards product={product} />
      <ProductTastingNotes product={product} />
      <ProductCertifications product={product} />
      <ProductAnalytics product={product} />
      <ProductGallery product={product} />
      <ProductVideos data={product} />
      <ProductRange product={product} />
      <WineVineyard wine={product} />
      <WineSoilType wine={product} />
      <WineWinemaker data={product} />
      <WineHarvestNotes wine={product} />
      <WineViticulture wine={product} />
      <WineVinification wine={product} />
      <WineMaturation wine={product} />
      <ProductSensory product={product} />
      <ProductPalateDescription product={product} />
      <ProductPairingDescription product={product} />
      <ProductBottleVariants product={product} />
      <ProductPackagingVariants product={product} />
    </Col>
  );
}

// export const fragment = graphql(`
//   fragment bb_ProductDetailsSection on Bottlebooks_Product {
//     ...bb_ProductDescription
//     ...bb_ProductAwards
//     ...bb_ProductTastingNotes
//     ...bb_ProductCertifications
//     ...bb_ProductAnalytics
//     ...bb_ProductVideos
//     ...bb_ProductRange
//     ...bb_WineWinemaker
//     ...bb_WineSoilType
//     ...bb_WineVineyard
//     ...bb_WineHarvestNotes
//     ...bb_WineViticulture
//     ...bb_WineVinification
//     ...bb_WineMaturation
//     ...bb_ProductPalateDescription
//     ...bb_ProductPairingDescription
//     ...bb_ProductBottleVariants
//     ...bb_ProductPackagingVariants
//   }
//   fragment ProductDetailsSection on Product {
//     ...ProductSensory
//   }
// `);

// export const ProductDetailsSectionFragment = gql`
//   fragment ProductDetailsSection on Product {
//     ...ProductDescription
//     ...ProductAwards
//     ...ProductTastingNotes
//     ...ProductCertifications
//     ...ProductAnalytics
//     ...ProductVideos
//     ...ProductSensory
//     ...ProductRange
//     ...WineVineyard
//     ...WineSoilType
//     ...WineWinemaker
//     ...WineHarvestNotes
//     ...WineViticulture
//     ...WineVinification
//     ...WineMaturation
//     ...ProductPairingDescription
//     ...ProductPalateDescription
//     ...ProductBottleVariants
//     ...ProductPackagingVariants
//   }
//   ${ProductDescription.fragment}
//   ${ProductAwards.fragment}
//   ${ProductTastingNotes.fragment}
//   ${ProductCertifications.fragment}
//   ${ProductAnalytics.fragment}
//   ${ProductVideos.fragment}
//   ${ProductSensoryFragment}
//   ${ProductRange.fragment}
//   ${WineVineyard.fragment}
//   ${WineSoilType.fragment}
//   ${WineWinemaker.fragment}
//   ${WineHarvestNotes.fragment}
//   ${WineViticulture.fragment}
//   ${WineVinification.fragment}
//   ${WineMaturation.fragment}
//   ${ProductPairingDescription.fragment}
//   ${ProductPalateDescription.fragment}
//   ${ProductBottleVariants.fragment}
//   ${ProductPackagingVariants.fragment}
// `;

// ProductDetailsSection.fragment = gql`
//   fragment ProductDetailsSection_RegisteredProduct on RegisteredProduct {
//     product {
//       ...ProductDetailsSection
//     }
//   }
//   ${ProductDetailsSectionFragment}
// `;
