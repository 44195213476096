/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import {
  Box,
  Flex,
  Link,
  MultilineText,
  OrganicEUIcon,
  OrganicIcon,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { SealCheck } from "@phosphor-icons/react";
import { graphql } from "~/gql";
// @ts-expect-error svg imports aren't allowed by default.
import vegan from "../../assets/vegan.svg";
import React from "react";
// @ts-expect-error svg imports aren't allowed by default.
import vegetarian from "../../assets/vegetarian.svg";
import Section from "../Section/Section";

function hasContent(product) {
  if (!product) return false;
  // We don't display 'no' values at all, so we don't need to check for false vs. null.
  // But let's make this more explicit by checking for `true` specifically.
  if (product.isVegan === true) return true;
  if (product.isVegetarian === true) return true;
  if (product.isCertifiedOrganic === true) return true;
  if (product.isOrganic === true) return true;
  if (product.isGmoFree === true) return true;
  if (product.localCertifications?.length) return true;
  if (product.internationalCertifications?.length) return true;
  if (product.otherSustainabilityCertifications) return true;
  return false;
}

export default function ProductCertifications({ product, ...rest }) {
  const { i18n } = useLingui();
  if (!hasContent(product)) return null;
  const certifications = [
    ...(product.internationalCertifications || []),
    ...(product.localCertifications || []),
  ];
  return (
    <Section title={<Trans>Certifications</Trans>} {...rest}>
      <Flex align="center" gap={3}>
        {product.isVegetarian && (
          <img
            src={vegetarian}
            alt={i18n._(t`Vegetarian`)}
            title={i18n._(t`Vegetarian`)}
            width="34"
            height="40"
          />
        )}
        {product.isVegan && (
          <img
            src={vegan}
            alt={i18n._(t`Vegan`)}
            title={i18n._(t`Vegan`)}
            width="34"
            height="40"
          />
        )}
        {product.isCertifiedOrganic ? (
          <OrganicEUIcon
            size="medium"
            alt={i18n._(t`Certified Organic`)}
            title={i18n._(t`Certified Organic`)}
            sx={{
              verticalAlign: "top",
              width: 42,
              height: 42,
            }}
          />
        ) : product.isOrganic ? (
          <OrganicIcon
            size="medium"
            alt={i18n._(t`Organic`)}
            title={i18n._(t`Organic`)}
            sx={{
              color: "lighterText",
              padding: 1,
              verticalAlign: "top",
              border: 1,
              borderRadius: "round",
              borderColor: "lighterText",
              width: 30,
              height: 30,
              marginY: "2px",
            }}
          />
        ) : null}
      </Flex>
      <Flex direction="column" gap={2} sx={{ marginTop: 3 }}>
        {product.isGmoFree && (
          <Flex align="center" gap={3}>
            <SealCheck size={32} />
            <Text>
              <Trans>GMO free</Trans>
            </Text>
          </Flex>
        )}

        {certifications.map((certification) => (
          <React.Fragment key={certification.name}>
            {certification.name ? (
              <Flex align="center" gap={3}>
                <SealCheck size={32} />

                {certification.fileUrl ? (
                  <Text>
                    <Link
                      href={certification.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {certification.name}
                    </Link>
                  </Text>
                ) : (
                  <Text>{certification.name} test</Text>
                )}
              </Flex>
            ) : certification.file ? (
              <Box sx={{ height: 25, display: "block", position: "relative" }}>
                <img
                  src={`https://res.cloudinary.com/bottlebooks/image/upload/h_25,dpr_2,c_pad,g_center,q_auto,f_auto/${certification.file.publicId}`}
                  alt={certification.name}
                  style={{
                    objectFit: "contain",
                    objectPosition: "left top",
                  }}
                />
              </Box>
            ) : null}
          </React.Fragment>
        ))}
      </Flex>
      <MultilineText text={product.otherSustainabilityCertifications} />
    </Section>
  );
}

export const fragment = graphql(`
  fragment bb_ProductCertifications on Bottlebooks_Product {
    isVegan
    isVegetarian
    isCertifiedOrganic
    isOrganic
    isGmoFree
    localCertifications {
      fileUrl
      name
    }
    internationalCertifications {
      fileUrl
      name
    }
    otherSustainabilityCertifications
  }
`);

ProductCertifications.fragment = gql`
  fragment ProductCertifications on Product {
    isVegan
    isVegetarian
    isCertifiedOrganic
    isOrganic
    isGmoFree
    localCertifications {
      fileUrl
      name
    }
    internationalCertifications {
      fileUrl
      name
    }
    otherSustainabilityCertifications
  }
`;
