/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Badge, Box, Label } from "@bottlebooks/gatsby-design-system";
import { graphql } from "~/gql";

export default function ExhibitorStand({ exhibitor, ...rest }) {
  if (!exhibitor?.stand?.name) return null;

  return (
    <Box {...rest}>
      {exhibitor.stand?.roomName ? (
        <Badge
          sx={{
            backgroundColor: "primary",
            color: "onPrimary",
            marginBottom: 1,
          }}
        >
          {exhibitor.stand?.roomName}
        </Badge>
      ) : null}
      <Label>{exhibitor.stand?.standTypeWithNumber}</Label>
    </Box>
  );
}

export const fragment = graphql(`
  fragment ExhibitorStand on Exhibitor {
    stand {
      name: title
    }
  }
  fragment bb_ExhibitorStand_SingleRegistration on Bottlebooks_SingleRegistration {
    stand {
      # Raw information
      name
      # Used for rendering
      standTypeWithNumber
      # Used for rendering
      roomName
    }
  }
`);

export const ExhibitorStandFragment = gql`
  fragment ExhibitorStand on Exhibitor {
    stand {
      name
    }
  }
  fragment ExhibitorStand_SingleRegistration on SingleRegistration {
    stand {
      name
      standTypeWithNumber
      roomName
    }
  }
`;
