/** @jsxImportSource theme-ui */
import { Container, Title, Box } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import gql from "graphql-tag";
import EventBannerImage from "./EventBannerImage";

export default function EventBanner({ event, withOverlay = true, ...rest }) {
  return (
    <EventBannerImage event={event} withOverlay={withOverlay}>
      {withOverlay && event?.name && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            alignItems: "stretch",
          }}
        >
          <Container sx={{ marginY: "auto", width: "100%" }}>
            <Title variant="banner">{event.name}</Title>
          </Container>
        </Box>
      )}
    </EventBannerImage>
  );
}

export const fragment = graphql(`
  fragment bb_EventBanner on Bottlebooks_Event {
    name
    ...bb_EventBannerImage
  }
  fragment EventBanner on Event {
    name
    ...EventBannerImage
  }
`);
EventBanner.fragment = gql`
  fragment EventBanner on Event {
    name
    ...EventBannerImage
  }
  ${EventBannerImage.fragment}
`;
