/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import {
  Box,
  Flex,
  P,
  SemiTitle,
  Text,
} from "@bottlebooks/gatsby-design-system";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { graphql } from "~/gql";
import OfferingBadge from "./OfferingBadge";

export default function MarketingActivity({ activity, variant, ...rest }) {
  const startDate = useDate(activity?.startDate);
  const endDate = useDate(activity?.endDate);
  if (!activity?.type) return null;
  return (
    <Box sx={{ paddingY: 3 }} {...rest}>
      <Flex>
        <SemiTitle sx={{ marginRight: "auto", marginTop: 0 }}>
          {activity.name}
        </SemiTitle>
        <OfferingBadge
          type={activity.type}
          variant={variant}
          direction="row-reverse"
        />
      </Flex>
      {(startDate || endDate) && (
        <Text variant="smallest" sx={{ paddingBottom: 2 }}>
          {startDate}
          {endDate && ` – ${endDate}`}
        </Text>
      )}
      {activity.description?.split?.("\n").map((p, index) => (
        <P variant="large" key={index}>
          {p}
        </P>
      ))}
      <Flex sx={{ marginTop: 3 }}>
        {activity.location && (
          <Text variant="smallest">
            <Link
              href={`https://maps.google.com/?q=${encodeURIComponent(
                activity.location
              )}`}
              variant="onPrimary"
            >
              {activity.location}
            </Link>
          </Text>
        )}
        {activity.url && (
          <Text variant="smallest" sx={{ marginLeft: "auto" }}>
            <Link href={activity.url} variant="onPrimary">
              <Trans>Read more</Trans>
            </Link>
          </Text>
        )}
      </Flex>
    </Box>
  );
}

const DATE_ONLY = {
  day: "numeric",
  month: "long",
};
const DATE_WITH_TIME = {
  ...DATE_ONLY,
  hour: "numeric",
  minute: "numeric",
};

function useDate(dateValue) {
  const { i18n } = useLingui();
  if (dateValue == null) return "";
  const date = new Date(dateValue);
  if (Number.isNaN(date.getDate())) return "";
  if (date.getHours() === 0 && date.getMinutes() === 0) {
    return i18n.date(date, DATE_ONLY);
  }
  return i18n.date(date, DATE_WITH_TIME);
}

export const fragment = graphql(`
  fragment MarketingActivity on MarketingActivity {
    type
    name
    startDate
    endDate
    location
    description
    url
  }
`);
export const MarketingActivityFragment = gql`
  fragment MarketingActivity on MarketingActivity {
    type
    name
    startDate
    endDate
    location
    description
    url
  }
`;
