/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Text } from "@bottlebooks/gatsby-design-system";
import {
  FacebookIcon,
  InstagramIcon,
  Link,
  TwitterIcon,
  YoutubeIcon,
} from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";

export default function BrandSocialMedia({ brand, ...rest }) {
  if (!brand) return null;
  const { facebookUrl } = brand;
  return (
    <AddressSegment>
      {facebookUrl && (
        <IconLink href={facebookUrl} title="Facebook">
          <FacebookIcon size="medium" sx={{ color: "primary" }} />
        </IconLink>
      )}
      {brand.instagramUrl && (
        <IconLink href={brand.instagramUrl} title="Instagram">
          <InstagramIcon size="medium" sx={{ color: "primary" }} />
        </IconLink>
      )}
      {brand.twitterUrl && (
        <IconLink href={brand.twitterUrl} title="Twitter">
          <TwitterIcon size="medium" sx={{ color: "primary" }} />
        </IconLink>
      )}
      {brand.youtubeUrl && (
        <IconLink href={brand.youtubeUrl} title="YouTube">
          <YoutubeIcon size="medium" sx={{ color: "primary" }} />
        </IconLink>
      )}
    </AddressSegment>
  );
}

function IconLink(props) {
  // Returns a link with enough padding to be touched with a finger (at least ~40px).
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <Link
      sx={{ display: "inline-block", marginRight: 1 }}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    />
  );
}

function AddressSegment({ children }) {
  if (!children) return null;
  return (
    <div sx={{ display: "flex", paddingBottom: 2 }}>
      <Text>{children}</Text>
    </div>
  );
}

export const fragment = graphql(`
  fragment BrandSocialMedia_Profile on Bottlebooks_Profile {
    facebookUrl: facebook(format: URL)
    instagramUrl: instagram(format: URL)
    twitterUrl: twitter(format: URL)
    youtubeUrl: youtube(format: URL)
  }
  fragment bb_BrandSocialMedia on Bottlebooks_AbstractBrand {
    facebookUrl: facebook(format: URL)
    instagramUrl: instagram(format: URL)
    twitterUrl: twitter(format: URL)
    youtubeUrl: youtube(format: URL)
  }
`);

export const BrandSocialMediaFragment = gql`
  fragment BrandSocialMedia on AbstractBrand {
    facebookUrl: facebook(format: URL)
    instagramUrl: instagram(format: URL)
    twitterUrl: twitter(format: URL)
    youtubeUrl: youtube(format: URL)
  }
`;
