/** @jsxImportSource theme-ui */
import { Box, Col, Container } from "@bottlebooks/gatsby-design-system";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import BrandDetailsSection from "../Brand/BrandDetailsSection.next";
import BrandDistributors from "../Brand/BrandDistributors.next";
import ContentRow from "../ContentRow";
import useSiteConfig from "../useSiteConfig";
import Representatives from "./Representatives.next";
import ExhibitorContacts from "./ExhibitorContacts.next";

const fragment = graphql(`
  fragment ExhibitorSections on SingleRegistration {
    ...Representatives
    profile {
      ...BrandDetailsSection
      ...BrandDistributors
    }
    __typename
    ... on SingleRegistration {
      registeredBrands {
        nodes {
          distributionContacts {
            ...Contact
          }
          ...BrandDetailsSection_PresentedBrand
        }
      }
    }
  }
`);

/**
 * A component to contain all the exhibitor details sections.
 */
export default function ExhibitorSections({
  data,
  eventId,
  ...rest
}: {
  data: FragmentType<typeof fragment> | null;
  eventId: string;
}) {
  const registration = useFragment(fragment, data);
  const profile = registration?.profile;
  const { showDistributionDetails } = useSiteConfig();
  if (!profile) throw new Error("No profile");
  return (
    <React.Fragment>
      <Box sx={{ paddingY: [0, 3] /*,  backgroundColor: 'light' */ }}>
        <Container sx={{ paddingX: [0, "gutter"] }} {...rest}>
          <ContentRow>
            <Col flex>
              <Representatives data={registration} sx={{ flex: "1 0 auto" }} />
            </Col>
          </ContentRow>
        </Container>
      </Box>
      <Container {...rest}>
        <ContentRow>
          <BrandDetailsSection
            data={registration.profile}
            presentedBrandsData={
              registration.__typename === "SingleRegistration"
                ? registration.registeredBrands.nodes
                : []
            }
          />
        </ContentRow>
        {["all", "global"].includes(showDistributionDetails) && (
          <ContentRow>
            <Col flex>
              <BrandDistributors brand={profile} />
            </Col>
          </ContentRow>
        )}
      </Container>
    </React.Fragment>
  );
}
