/** @jsxImportSource theme-ui */
import Card from "@bottlebooks/gatsby-design-system/src/components/Card/Card";
import { Box, Flex, Label, Text } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import {
  ToggleFavorite,
  useFavorite,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { getFluidImage } from "../../helpers/getImageProps";
import BrandHeader from "../Brand/BrandHeader";
import BrandLogo from "../Brand/BrandLogo";
import OfferingBadge from "../Brand/OfferingBadge";
import ExhibitorStand from "../Exhibitor/ExhibitorStand";
import ListRow from "../List/ListRow";
import ListRowBackgroundImage from "../List/ListRowBackgroundImage";
import ListRowBody from "../List/ListRowBody";

/**
 *
 * @param {object} props
 * @param {object} props.producer
 * @param {object} [props.exhibitor]
 * @param {Parameters<Card>[0]["elevation"]} [props.elevation]
 * @param {string} [props.to]
 * @param {React.MouseEventHandler} [props.onClick]
 * @param {React.ReactNode} [props.children]
 * @param {import('react-beautiful-dnd').DraggableProvidedDragHandleProps} [props.dragHandleProps]
 * @returns
 */
export default function ProducerListRow({
  producer,
  exhibitor,
  elevation,
  children,
  dragHandleProps,
  to,
  onClick,
  ...rest
}) {
  // TODO use registrationId: producer.registrationId,
  const favorite = useFavorite(
    producer && { type: "registration", id: producer.exhibitorId }
  );
  const isPresentedByAnotherExhbitor =
    exhibitor &&
    producer.producerId !== exhibitor?.exhibitorId &&
    producer.name !== exhibitor?.name;

  const mainImage = producer?.mainImage?.fluid;
  const hasBackground = Boolean(mainImage);
  return (
    <Card elevation={elevation}>
      {isPresentedByAnotherExhbitor && (
        <ListRow
          to={to}
          onClick={onClick}
          sx={{
            overflow: "visible",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <BrandLogo
            brand={exhibitor}
            onDark={hasBackground}
            size="small"
            sx={{
              backgroundColor: "background",
              paddingX: 4,
              paddingY: 2,
              position: "absolute",
              top: 2,
              left: 2,
              transformOrigin: "0 0",
              transform: "scale(0.5)",
              boxShadow: "medium",
              zIndex: 1,
            }}
          />
          <ListRowBody
            sx={{
              backgroundColor: "light",
              paddingY: 1,
              paddingLeft: 116,
              paddingRight: 5,
              flexGrow: 1,
              borderBottom: 1,
              borderColor: "border",
            }}
          >
            <Text variant="smallest" sx={{ color: "lighterText" }}>
              <Trans>Presented by</Trans>
            </Text>
            <Label>{exhibitor.name}</Label>
          </ListRowBody>
        </ListRow>
      )}
      <ListRow
        onDark={hasBackground}
        to={isPresentedByAnotherExhbitor ? undefined : to}
        onClick={isPresentedByAnotherExhbitor ? undefined : onClick}
        sx={{
          overflow: "visible", // Show the shadow around the logo.
          flexDirection: "row",
          alignItems: "center",
          // Only add hover to links. The `&` is replaced by the class name automatically.
          "a[href]&:hover": {
            " > .bottlebooks-bgimg": {
              transform: "scale(1.01)",
              "::after": {
                backgroundColor: "overlaySecondary",
              },
            },
          },
        }}
        {...rest}
      >
        {hasBackground && (
          <ListRowBackgroundImage
            fluid={getFluidImage(mainImage)}
            className="bottlebooks-bgimg"
          />
        )}
        <Box sx={{ padding: 2, width: 100, height: 100 }}>
          <BrandLogo
            brand={producer}
            size="small"
            onDark={hasBackground}
            sx={{
              backgroundColor: "background",
              position: "absolute",
              top: 0,
              left: 0,
              width: 84,
              height: 84,
              objectFit: "cover",
              objectPosition: "center center",
              opacity: 1,
              transition: "opacity 500ms ease 0s",
            }}
            {...dragHandleProps}
          />
        </Box>
        <ListRowBody
          sx={{
            paddingRight: isPresentedByAnotherExhbitor ? undefined : 5,
            flexGrow: 1,
            paddingY: 2.5,
          }}
        >
          <ExhibitorStand exhibitor={exhibitor} sx={{ color: "lighterText" }} />
          <BrandHeader gap={0} brand={producer} onDark={hasBackground} />
        </ListRowBody>
      </ListRow>
      <ToggleFavorite
        sx={{ position: "absolute", top: 0, right: 0 }}
        isToggled={favorite.isSet}
        variant="small"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          favorite.toggle();
        }}
      />
      <Flex
        direction="column"
        sx={(theme) => ({
          position: "absolute",
          bottom: 3,
          right: 3,
          color: hasBackground ? "onDark" : "inherit",
          textShadow: hasBackground
            ? `1px 1px ${theme.colors?.black}, 0 0 20px ${theme.colors?.black}`
            : undefined,
        })}
      >
        <BrandFeatures brand={producer} />
      </Flex>
    </Card>
  );
}

export function BrandFeatures({ brand }) {
  if (!brand.offeringTypes?.length) return null;
  return (
    <Flex
      justify="flex-end"
      align="flex-end"
      gap={2}
      sx={{
        width: "100%",
        paddingRight: 2,
        paddingBottom: 2,
        position: "relative", // Needed to stay above image backgrounds
        marginLeft: "auto",
      }}
    >
      {brand.offeringTypes?.map((type) => (
        <OfferingBadge key={type} variant="onPrimary" type={type} />
      ))}
    </Flex>
  );
}

export const fragment = graphql(/* GraphQL */ `
  fragment bb_ProducerListRow on Bottlebooks_Producer {
    offeringTypes
  }
  fragment bb_ProducerListRow_Registration on Bottlebooks_Registration {
    registrationId
    exhibitorId: companyId
  }
  fragment ProducerListRow_mainImage on Producer {
    mainImage {
      fluid(maxWidth: 700, maxHeight: 200, crop: FILL, gravity: FACES_AUTO) {
        src
        sizes
        aspectRatio
      }
    }
  }
  fragment ProducerListRow on Producer {
    producerId
    exhibitorId
    ...BrandLogoLarge
    ...ProducerHeader
    ...ProducerListRow_mainImage
  }

  # A fragment for the first page of exhibitors. It has more detailed images.
  fragment ProducerListRow_first on Producer {
    ...BrandLogoLarge
    mainImage {
      fluid(maxWidth: 700, maxHeight: 200, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
`);
