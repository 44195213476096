/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { graphql } from "~/gql";
import React from "react";
import BrandHeader, { BrandHeaderFragment } from "../Brand/BrandHeader";
import ProducerStand, { ProducerStandFragment } from "./ProducerStand";
import useLink from "../../useLink";
import BrandSeekingDistribution from "../Brand/BrandSeekingDistribution";

/**
 *
 * @param {object} props
 * @param {object} props.exhibitor
 * @param {object} props.producer
 * @param {boolean} [props.onDark]
 * @param {number} [props.gap]
 * @param {string | React.ReactElement} [props.as]
 * @returns
 */
export default function ProducerHeader({
  exhibitor,
  producer,
  onDark,
  gap = 2,
  as,
  ...rest
}) {
  const link = useLink();
  return (
    <React.Fragment>
      <ProducerStand
        exhibitor={exhibitor}
        producer={producer || {}}
        to={link.exhibitor(exhibitor)}
        sx={{ paddingBottom: gap, color: onDark ? "onDark" : "lighterText" }}
      />
      <BrandHeader as={as} brand={producer || {}} {...rest} />
      <BrandSeekingDistribution brand={producer} sx={{ marginTop: 3 }} />
    </React.Fragment>
  );
}

export const fragment = graphql(`
  fragment bb_Registration_ProducerHeader on Bottlebooks_Registration {
    ...bb_Registration_ProducerStand
  }
  fragment bb_RegisteredBrand_ProducerHeader on Bottlebooks_RegisteredBrand {
    ...bb_RegisteredBrand_SeekingDistribution
  }
  fragment ProducerHeader on Producer {
    ...ProducerStand
    ...BrandHeader
  }
`);

export const ProducerHeaderFragment = gql`
  ${ProducerStandFragment}
  ${BrandHeaderFragment}
  fragment bb_Registration_ProducerHeader on Bottlebooks_Registration {
    ...bb_Registration_ProducerStand
  }
  fragment ProducerHeader on AbstractBrand {
    ...ProducerStand
    ...BrandHeader
  }
`;
