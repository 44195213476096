/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import gql from "graphql-tag";
import CoverImage from "../CoverImage";

/**
 *
 * @param {{
 * event?: { mainImage?: { fluid?: any } | null; name?: string | null };
 * children?: React.ReactNode;
 * className?: string;
 * withOverlay?: boolean;
 * }} props
 * @returns
 */
export default function EventBannerImage({
  event,
  children,
  className = undefined,
  withOverlay,
}) {
  const image = event?.mainImage;
  const name = event?.name;
  const hasImage = Boolean(image);
  return (
    <Box
      sx={{
        color: hasImage ? "onDark" : undefined,
        backgroundColor: "nuanced",
        position: "relative",
      }}
      className={className}
    >
      {hasImage && (
        <CoverImage
          fluid={image?.fluid}
          alt={name || ""}
          loading="eager"
          withOverlay={withOverlay}
          sx={{ minHeight: [200] }}
        />
      )}
      {children}
    </Box>
  );
}

export const fragment = graphql(`
  fragment bb_EventBannerImage on Bottlebooks_Event {
    name
    mainImage {
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
  fragment EventBannerImage on Event {
    name
    mainImage {
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
`);
EventBannerImage.fragment = gql`
  fragment EventBannerImage on Event {
    name
    mainImage {
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
`;
