/** @jsxImportSource theme-ui */
import { FeaturedProductContentType } from "~/gql/graphql";
import OnlineShopIcon from "../Badge/OnlineShopIcon";
import VideosIcon from "../Badge/VideosIcon";

export default function ProductFeatureIcon({
  type,
  variant = "default",
}: {
  type: FeaturedProductContentType;
  variant?: keyof typeof iconVariants;
}) {
  if (type === "PRICE") throw new Error("PRICE is not implemented yet.");
  const Icon = icons[type];
  return <Icon {...iconVariants[variant]} />;
}

const iconVariants = {
  onDark: { sx: { color: "onPrimary" } },
  onPrimary: { sx: { color: "primary" }, backgroundColor: "white" },
  default: { sx: { color: "primary" }, backgroundColor: "white" },
} as const;

const icons = {
  WEBSHOP: OnlineShopIcon,
  VIDEOS: VideosIcon,
  // TODO: Not sure what price means
  // price: VirtualTastingIcon,
};
