/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";

export default function BookMeetingButton({ brand, ...rest }) {
  if (!hasContent(brand)) return null;
  return (
    <Button
      variant="primary"
      href={brand.meetingBookingUrl}
      openInNewTab
      {...rest}
    >
      Book a meeting
    </Button>
  );
}
BookMeetingButton.hasContent = hasContent;

function hasContent(brand) {
  if (!brand) return false;
  if (brand.meetingBookingUrl) return true;
  return false;
}

export const fragment = graphql(`
  fragment bb_BookMeetingButton on Bottlebooks_Exhibitor {
    meetingBookingUrl
  }
`);

BookMeetingButton.fragment = gql`
  fragment BookMeetingButton on Exhibitor {
    meetingBookingUrl
  }
`;
