/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import FilterField from "../Filters/FilterField";
import SearchResults from "./SearchResults.next";
import useSiteSearch from "./useSiteSearch.next";

const fragment = graphql(/* GraphQL */ `
  fragment SiteSearch on Collection {
    ...useSiteSearch
    registrations {
      nodes {
        ...ExhibitorSearchResults
      }
    }
    registeredProducts {
      nodes {
        ...ProductSearchResults
      }
    }
  }
`);

export default function SiteSearch({
  initialTerm = "",
  variant,
  locale,
  ...rest
}: {
  initialTerm: string;
  variant: string;
  locale: string;
}) {
  const search = useSiteSearch({ initialTerm });
  return (
    <Box>
      <FilterField
        variant={variant}
        value={search.term}
        onChange={search.setTerm}
        {...rest}
      />
      {search.term && (
        <SearchResults
          searchTerm={search.term}
          exhibitors={search.exhibitors}
          products={search.products}
          onDismiss={() => search.setTerm("")}
          sx={{
            marginTop: 2,
            zIndex: 11, // TODO make this a portal instead.
            backgroundColor: ["light", null, "white"],
            minWidth: [null, 500],
          }}
        />
      )}
    </Box>
  );
}
