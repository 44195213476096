/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Gallery from "../Gallery/Gallery";
import Section from "../Section/Section";

export default function BrandGallery({ brand, ...rest }) {
  if (!hasContent(brand)) return null;
  return (
    <Section title={<Trans>Gallery</Trans>} {...rest}>
      <Gallery images={brand.galleryImages} />
    </Section>
  );
}
BrandGallery.hasContent = hasContent;
function hasContent(brand) {
  if (!brand) return false;
  if (!brand.galleryImages?.filter(Boolean).length) return false;
  if (brand?.__typeName !== "Exhibitor") return true;
  return false;
}

export const fragment = graphql(`
  fragment BrandGallery_Profile on Bottlebooks_Profile {
    galleryImages {
      ...Gallery
    }
  }
  fragment BrandGallery on Bottlebooks_AbstractBrand {
    galleryImages {
      ...Gallery
    }
  }
`);

export const BrandGalleryFragment = gql`
  fragment BrandGallery on AbstractBrand {
    galleryImages {
      ...Gallery
    }
  }
  ${Gallery.fragment}
`;
