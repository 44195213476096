/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Box, Text } from "@bottlebooks/gatsby-theme-base/src";

export default function ProductLabel({ product, ...rest }) {
  const badgeLabel = [product.flightName, product.productLabel]
    .filter(Boolean)
    .join(" ");
  if (!badgeLabel) return null;
  return (
    <Box
      sx={{
        paddingY: 1,
        paddingX: 3,
        backgroundColor: "light",
        borderRadius: 999,
        display: "inline-block",
        border: 1,
        borderColor: "border",
      }}
      {...rest}
    >
      <Text>{badgeLabel}</Text>
    </Box>
  );
}

export const ProductLabelFragment = gql`
  fragment bb_ProductLabel_RegisteredProduct on RegisteredProduct {
    flightName
    productLabel
  }
`;
