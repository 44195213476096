/** @jsxImportSource theme-ui */
import { SemiTitle, View } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { graphql } from "~/gql";
import Scale from "../Scale/Scale";
import { gql } from "@apollo/client";

function hasContent({ palateAcidity, palateBody, palateTannins }) {
  if (palateAcidity != null) return true;
  if (palateBody != null) return true;
  if (palateTannins != null) return true;
  return false;
}

export default function ProductPalate({ product }) {
  const { i18n } = useLingui();
  if (!product || !hasContent(product)) return null;
  const { palateAcidity, palateBody, palateTannins } = product;
  return (
    <View>
      <SemiTitle>
        <Trans>Taste</Trans>
      </SemiTitle>

      <Scale
        text={
          <Trans>Acidity: {i18n._(`palateAcidity.${palateAcidity}`)}</Trans>
        }
        value={acidityScale[palateAcidity]}
        max={3}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={<Trans>Body: {i18n._(`palateBody.${palateBody}`)}</Trans>}
        value={bodyScale[palateBody]}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={
          <Trans>Tannins: {i18n._(`palateTannins.${palateTannins}`)}</Trans>
        }
        value={tanninScale[palateTannins]}
        sx={{ maxWidth: 280 }}
      />
    </View>
  );
}
ProductPalate.hasContent = hasContent;

// The scale is used to give each text value a ranking.
const acidityScale = {
  low: 1,
  medium: 2,
  high: 3,
};
const bodyScale = {
  light: 1,
  moderate: 2,
  full: 3,
  complex: 4,
};
const tanninScale = {
  trace: 0,
  mild: 1,
  soft: 2,
  moderate: 3,
  intense: 4,
};

export const fragment = graphql(`
  fragment ProductPalate on Product {
    palateAcidity
    palateBody
    palateTannins
  }
`);

export const ProductPalateFragment = gql`
  fragment ProductPalate on Product {
    palateAcidity
    palateBody
    palateTannins
  }
`;
