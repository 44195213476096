/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { FormattedText } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import React from "react";
import Section from "../Section/Section";

function hasContent(product) {
  if (!product) return false;
  if (product.productRange) return true;
  if (product.rangeDescription) return true;
  return false;
}

export default function ProductRange({ product, ...rest }) {
  if (!hasContent(product)) return null;
  return (
    <Section
      title={
        <React.Fragment>
          <Trans>Product Range</Trans>
          {product.productRange && (
            <React.Fragment>: {product.productRange}</React.Fragment>
          )}
        </React.Fragment>
      }
      {...rest}
    >
      <FormattedText variant="large" text={product.rangeDescription} />
    </Section>
  );
}

export const fragment = graphql(`
  fragment bb_ProductRange on Bottlebooks_Product {
    productRange
    ... on Bottlebooks_Wine {
      rangeDescription
    }
  }
`);

ProductRange.fragment = gql`
  fragment ProductRange on Product {
    productRange
    ... on Wine {
      rangeDescription
    }
  }
`;
