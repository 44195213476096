/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Box, Text, Title } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";

function hasContent(bottleVariant) {
  if (!bottleVariant) return false;
  if (bottleVariant.GTIN) return true;
  if (bottleVariant.bottleSize) return true;
  if (bottleVariant.closureType) return true;
  if (bottleVariant.bottleNetWeight) return true;
  return false;
}
export default function BottleVariant({ bottleVariant, ...rest }) {
  if (!hasContent(bottleVariant)) return null;

  return (
    <Box {...rest}>
      {bottleVariant.bottleSize ? (
        <Value title={<Trans comment="Bottle size">Size</Trans>}>
          {bottleVariant.bottleSize}
        </Value>
      ) : null}
      {bottleVariant.bottleNetWeight ? (
        <Value title={<Trans>Weight</Trans>}>
          {bottleVariant.bottleNetWeight}g
        </Value>
      ) : null}
      {bottleVariant.GTIN ? (
        <Value title={<Trans>GTIN</Trans>}>{bottleVariant.GTIN}</Value>
      ) : null}
      {bottleVariant.closureType ? (
        <Value title={<Trans>Closure</Trans>}>
          {bottleVariant.closureType}
        </Value>
      ) : null}
    </Box>
  );
}
BottleVariant.hasContent = hasContent;
/**
 * This picks the fields from the variant that are actually used in this component.
 * We can use this in the parent component to determine the unique values.
 * @param {object} variant
 * @param {string?} variant.GTIN
 * @param {string?} variant.bottleSize
 * @returns
 */
BottleVariant.getContent = ({ GTIN, bottleSize }) => ({ GTIN, bottleSize });

function Value({ title, children, ...rest }) {
  if (!children) return null;
  return (
    <Text {...rest}>
      {title}: {children}
    </Text>
  );
}

export const fragment = graphql(`
  fragment bb_BottleVariant on Bottlebooks_ProductBottleVariant {
    GTIN
    bottleSize
    closureType
  }
`);

BottleVariant.fragment = gql`
  fragment BottleVariant on ProductBottleVariant {
    GTIN
    bottleSize
    closureType
  }
`;
