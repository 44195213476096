/** @jsxImportSource theme-ui */
import { graphql } from "~/gql";
import type { RequestMeetingButtonData } from "../Meeting/RequestMeetingButton";
import RequestMeetingButton, {
  RequestMeetingButtonSkeleton,
} from "../Meeting/RequestMeetingButton";
import type { RequestChatButtonData } from "./RequestChatButton";
import RequestChatButton, {
  RequestChatButtonSkeleton,
} from "./RequestChatButton";
import type { UserProfileCardProps } from "./UserProfileCard";
import UserProfileCard, { UserProfileCardSkeleton } from "./UserProfileCard";
import type { UserProfileCardCompanyData } from "./UserProfileCard.Company";
import type { UserProfileCardDisplayNameData } from "./UserProfileCard.DisplayName";
import type { UserProfileCardLinkedInData } from "./UserProfileCard.LinkedIn";
import type { Prettify } from "~/Prettify";
import type { UserProfileCardAvatarData } from "./UserProfileCard.Avatar";
import { gql } from "@apollo/client";
import UserProfileCardAvatar from "./UserProfileCard.Avatar";
import UserProfileCardDisplayName from "./UserProfileCard.DisplayName";
import UserProfileCardCompany from "./UserProfileCard.Company";
import UserProfileCardLinkedIn from "./UserProfileCard.LinkedIn";

type HorizontalProps = Omit<UserProfileCardProps, "children"> & {
  profile: UserProfileCardHorizontalData;
  hideActions?: boolean;
};

export default function UserProfileCardHorizontal({
  profile,
  hideActions,
  ...rest
}: HorizontalProps) {
  return (
    <UserProfileCard profile={profile} direction="horizontal" {...rest}>
      <UserProfileCard.Avatar profile={profile} />
      <UserProfileCard.Main>
        <UserProfileCard.DisplayName profile={profile} />
        <UserProfileCard.Company profile={profile} />
        <UserProfileCard.LinkedIn profile={profile} />
        {!hideActions && (
          <UserProfileCard.Actions>
            <RequestChatButton profile={profile} />
            <RequestMeetingButton profile={profile} />
          </UserProfileCard.Actions>
        )}
      </UserProfileCard.Main>
    </UserProfileCard>
  );
}

// export const fragment = graphql(`
//   fragment UserProfileCardHorizontal on Bottlebooks_SiteUserProfile {
//     ...UserProfileCardAvatar
//     ...UserProfileCardDisplayName
//     ...UserProfileCardCompany
//     ...UserProfileCardLinkedIn
//     ...RequestChatButton
//     ...RequestMeetingButton
//   }
// `);

UserProfileCardHorizontal.fragment = gql`
  fragment UserProfileCardHorizontal on SiteUserProfile {
    registrationId
    ...UserProfileCardAvatar
    ...UserProfileCardDisplayName
    ...UserProfileCardCompany
    ...UserProfileCardLinkedIn
    ...RequestChatButton
    # ...RequestMeetingButton
  }
  ${UserProfileCardAvatar.fragment}
  ${UserProfileCardDisplayName.fragment}
  ${UserProfileCardCompany.fragment}
  ${UserProfileCardLinkedIn.fragment}
  ${RequestChatButton.fragment}
  # TODO This doesn't work because it is undefined. This might be because we have a circular dependency.
  # $ {RequestMeetingButton.fragment}
`;

export type UserProfileCardHorizontalData = Prettify<
  UserProfileCardAvatarData &
    UserProfileCardDisplayNameData &
    UserProfileCardCompanyData &
    UserProfileCardLinkedInData &
    RequestChatButtonData &
    RequestMeetingButtonData
>;

export type UserProfileCardHorizontalSkeletonProps = Omit<
  HorizontalProps,
  "profile"
>;

export function UserProfileCardHorizontalSkeleton({
  hideActions,
  ...rest
}: UserProfileCardHorizontalSkeletonProps) {
  return (
    <UserProfileCardSkeleton direction="horizontal" {...rest}>
      <UserProfileCardSkeleton.Avatar />
      <UserProfileCardSkeleton.Main>
        <UserProfileCardSkeleton.DisplayName />
        <UserProfileCardSkeleton.Company />
        <UserProfileCardSkeleton.LinkedIn />
        {!hideActions && (
          <UserProfileCardSkeleton.Actions>
            <RequestChatButtonSkeleton />
            <RequestMeetingButtonSkeleton />
          </UserProfileCardSkeleton.Actions>
        )}
      </UserProfileCardSkeleton.Main>
    </UserProfileCardSkeleton>
  );
}
