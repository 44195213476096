/** @jsxImportSource theme-ui */
import {
  Col,
  Container,
  Label,
  Link,
  Row,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
// @ts-ignore Ignore default export error for lodash/chunk.
import chunk from "lodash/chunk";
// @ts-ignore Ignore default export error for React.
import React from "react";
import useLink from "../../useLink";
import BrandCarousel from "../BrandCarousel";
import useMenuQuery from "../Layout/useMenuQuery";

export default function FeaturedProducersSection({ producers, locale }) {
  const link = useLink();
  const menuItems = useMenuQuery({ locale });
  const producerMenu = menuItems.find(({ type }) => type === "producers");
  const brandChunks = chunk(producers, 2);
  return (
    <React.Fragment>
      <SectionTitle
        to={link.producers()}
        linkText={
          producerMenu.name ? (
            <Trans comment="dynamic replacement for 'See all producers'">
              See all {producerMenu.name} →
            </Trans>
          ) : (
            <Trans>See all producers →</Trans>
          )
        }
      >
        <Link to={link.producers()} sx={{ color: "inherit" }}>
          {producerMenu.name ? (
            <Trans comment="dynamic replacement for 'Our producers'">
              Our {producerMenu.name}
            </Trans>
          ) : (
            <Trans>Our producers</Trans>
          )}
        </Link>
      </SectionTitle>

      <BrandCarousel
        as="section"
        brandChunks={brandChunks}
        showIndicators
        sx={{ backgroundColor: "white" }}
      />
    </React.Fragment>
  );
}

function SectionTitle({ children, to, linkText, ...rest }) {
  return (
    <Container sx={{ paddingY: [0, 2], marginBottom: 4 }} {...rest}>
      <Row direction={["column", "row"]} align="baseline">
        <Col flex>
          <Title sx={{ color: "title" }}>{children}</Title>
        </Col>
        <Col>
          <Link to={to} sx={{ display: "block" }}>
            <Label>{linkText}</Label>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export const fragment = graphql(`
  fragment FeaturedProducersSection on Producer {
    ...BrandCarousel
    useLink_Producer
  }
`);
