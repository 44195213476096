/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import {
  AwardIcon,
  MultilineText,
  P,
  Text,
} from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import Section from "../Section/Section";

export default function ProductAwards({ product, ...rest }) {
  if (!hasContent(product)) return null;
  return (
    <Section title={<Trans>Awards</Trans>} {...rest}>
      {product.awards?.map((award) => (
        <P key={award.award}>
          <AwardIcon sx={{ marginRight: 2 }} />
          {award.medal && (
            <Text as="span" variant="semiTitle" sx={{ marginRight: 2 }}>
              {award.medal}{" "}
            </Text>
          )}
          {award.award}
        </P>
      ))}
      <MultilineText text={product.otherAwards} />
    </Section>
  );
}

function hasContent(product) {
  if (!product) return false;
  if (product.awards?.length) return true;
  if (product.otherAwards) return true;
  return false;
}

export const fragment = graphql(`
  fragment bb_ProductAwards on Bottlebooks_Product {
    productId
    awards {
      award
      medal
    }
    otherAwards
  }
`);

ProductAwards.fragment = gql`
  fragment ProductAwards on Product {
    productId
    awards {
      award
      medal
    }
    otherAwards
  }
`;
