/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import {
  Button,
  Grid,
  Image,
  Link,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import type { CloudinaryFixed } from "@bottlebooks/graphql-server/src";
import { Plural, Trans } from "@lingui/macro";
import { graphql } from "~/gql";
import React from "react";
import type { LightboxImage } from "./GalleryLightbox";
import GalleryLightbox from "./GalleryLightbox";

interface TGalleryImage extends LightboxImage {
  title: string | null;
  image: CloudinaryFixed | null;
}

interface GalleryProps {
  images: TGalleryImage[] | null;
}

interface GalleryImageProps extends TGalleryImage {
  className?: string;
  onClick?: () => void;
  to?: string;
}

export default function Gallery({ images: data, ...rest }: GalleryProps) {
  // 12 is the optimal number for a grid (12 is the lowest common multiple of 2, 3, and 4):
  // - mobile:  6x2 (2 images per row, 6 rows)
  // - tablet:  4x3 (3 images per row, 4 rows)
  // - desktop: 3x4 (4 images per row, 3 rows)
  const maxImages = 12;
  const images = data?.filter((image) => Boolean(image.image));
  const mayExpand = images ? images.length > maxImages : false;
  const [isExpanded, expand] = React.useState(false);
  const [currentIndex, showIndex] = React.useState<null | number>(null);
  const nextIndex =
    currentIndex == null || !images?.length
      ? 0
      : (currentIndex + 1) % images.length;
  const previousIndex =
    currentIndex == null || !images?.length
      ? 0
      : (currentIndex + images.length - 1) % images.length;

  if (!images) return null;
  return (
    <React.Fragment>
      <Grid
        gap={2}
        sx={{
          gridTemplateColumns: `repeat(auto-fill, minmax(180px, 1fr))`,
          // We limit to only three rows if the grid isn't expanded. This works in mobile and desktop.
          gridTemplateRows: "auto auto auto",
          gridAutoRows: isExpanded ? undefined : "0",
          rowGap: 0,
        }}
        {...rest}
      >
        {images
          .slice(0, isExpanded ? undefined : maxImages)
          .map((image, index) =>
            image.image ? (
              <GalleryImage
                key={image.image.src}
                {...image}
                sx={{ marginBottom: 2, overflow: "hidden" }}
                onClick={() => showIndex(index)}
              />
            ) : null
          )}
      </Grid>
      {mayExpand && !isExpanded && (
        <Button variant="outline" onClick={() => expand(true)}>
          <Plural
            value={images.length}
            one="Show # image"
            other="Show all # images"
          />
        </Button>
      )}
      {isExpanded && (
        <Button variant="outline" onClick={() => expand(false)}>
          <Trans>Show less</Trans>
        </Button>
      )}
      {currentIndex == null ? null : (
        <GalleryLightbox
          sx={{ fontFamily: "text", color: "red" }}
          current={images[currentIndex]}
          next={images[nextIndex]}
          previous={images[previousIndex]}
          onClose={() => showIndex(null)}
          onPrevious={() => showIndex(previousIndex)}
          onNext={() => showIndex(nextIndex)}
        />
      )}
    </React.Fragment>
  );
}

function GalleryImage({
  image: thumb,
  title,
  full,
  fullPreview,
  description,
  className,
  onClick,
  to,
  ...rest
}: GalleryImageProps) {
  if (!thumb?.src) return null;
  return (
    <Link
      variant="text"
      className={className}
      onClick={onClick}
      to={to}
      sx={{
        position: "relative",
        display: "block",
        transition: "outline",
        outline: "2px solid",
        outlineColor: "transparent",
        ":hover:is(button,a[href]), :focus:is(button,a[href])": {
          outlineColor: "primary",
        },
      }}
    >
      <Image
        alt={title || ""}
        title={title}
        // @ts-ignore We check src already, but the other props can safely be ignored
        fluid={{
          ...thumb,
          sizes: "(max-width: 360px) 100vw, (max-width: 40em) 50vw, 272px",
        }}
        {...rest}
      />
      {title && (
        <Text
          variant="small"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 2,
            backgroundColor: "overlay",
            color: "onDark",
          }}
          // Clamp the text to 3 lines in browsers that support it (Chrome/Firefox), falling back to 45% of the image height.
          // @ts-ignore lineClamp needs to be set to a string because theme-ui changes it to pixels. That doesn't match the type of lineClamp.
          css={{
            maxHeight: "45%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // The Webkit hack:
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            // Standard line clamping:
            lineClamp: "3",
          }}
        >
          {title}
        </Text>
      )}
    </Link>
  );
}

// export const fragment = graphql(`
//   fragment Gallery on Bottlebooks_UploadedFile {
//     title
//     image: fluid(maxWidth: 360, maxHeight: 360, crop: FILL) {
//       src
//       srcSet
//       sizes
//       width
//       height
//       aspectRatio
//     }
//     ...GalleryLightbox
//   }
// `);

Gallery.fragment = gql`
  fragment Gallery on UploadedFile {
    title
    image: fluid(maxWidth: 360, maxHeight: 360, crop: FILL) {
      src
      srcSet
      sizes
      width
      height
      aspectRatio
    }
    ...GalleryLightbox
  }

  ${GalleryLightbox.fragment}
`;
